import React from 'react';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SearchCustomer from '@Components/calendar/booking/search-customer';
import styled from 'styled-components';

const Container = styled.div`
  .booking-form-body {
    padding: 0;
  }
`;

const SearchCustomerModal = ({ onCancel, ...props }) => (
  <ModalDialog
    contentSize="medium"
    title="Lägg till kund"
    onClose={onCancel}
    closeButtonText="Stäng"
  >
    <Container>
      <SearchCustomer noChrome {...props} />
    </Container>
  </ModalDialog>
);

export default SearchCustomerModal;
