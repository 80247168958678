import { Map } from 'immutable';
import { RESET_BKF } from '@State/bkf/constants';
import { SET_CF_STATE, OPEN_TAB } from './cf-actions';
import { CLEAR_LOCATION_STATE } from './account-actions';

function clearState(state) {
  return state.withMutations((map) => {
    map.set('state', 'NOT_LOADED');
    map.set('formVisible', false);
  });
}

function setState(state, action) {
  return state.withMutations((map) => {
    map.set('state', 'LOADED');
    map.set('formVisible', action.formVisible);
    map.set('tab', action.tab || 'overview');
    map.set('tabId', action.tabId || null);
    map.set('customer', action.customer);
  });
}

export function cf(state = Map({
  state: 'NOT_LOADED',
  formVisible: false,
  customer: null,
  tab: 'overview',
  tabId: null
}), action = null) {
  switch (action.type) {
    case OPEN_TAB:
      return state.set('tab', action.tab).set('tabId', action.tabId);
    case CLEAR_LOCATION_STATE:
    case RESET_BKF:
      return clearState(state, action);
    case SET_CF_STATE:
      return setState(state, action);
    default:
      return state;
  }
}
