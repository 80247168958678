import { defineMessages } from 'react-intl';

export default defineMessages({
  customersType: {
    id: 'reports.dataExport.customersType',
    defaultMessage: 'Report type'
  },
  customersTypeNew: {
    id: 'reports.dataExport.customersTypeNew',
    defaultMessage: 'New customers - created within selected dates'
  },
  customersTypeTop: {
    id: 'reports.dataExport.customersTypeTop',
    defaultMessage: 'Top customers - spent the most within selected dates'
  },
  customersLimit: {
    id: 'reports.dataExport.customersLimit',
    defaultMessage: 'Max number of customers'
  },
  exportData: {
    id: 'reports.dataExport.exportData',
    defaultMessage: 'Export data'
  },
  dateInterval: {
    id: 'reports.dataExport.dateInterval',
    defaultMessage: 'Date interval'
  },
  resources: {
    id: 'reports.dataExport.resources',
    defaultMessage: 'Resources'
  },
  selectResources: {
    id: 'reports.dataExport.selectResources',
    defaultMessage: 'Select resources'
  },
  locations: {
    id: 'reports.dataExport.locations',
    defaultMessage: 'Locations'
  },
  selectLocations: {
    id: 'reports.dataExport.selectLocations',
    defaultMessage: 'Select locations'
  },
  services: {
    id: 'reports.dataExport.services',
    defaultMessage: 'Services'
  },
  selectServices: {
    id: 'reports.dataExport.selectServices',
    defaultMessage: 'Select services'
  },
  dataToExport: {
    id: 'reports.dataExport.dataToExport',
    defaultMessage: 'Data to export'
  },
  dataToExportPlaceholder: {
    id: 'reports.dataExport.dataToExportPlaceholder',
    defaultMessage: 'Data to export'
  },
  customFields: {
    id: 'reports.dataExport.customFields',
    defaultMessage: 'Custom fields'
  },
  customFieldsPlaceholder: {
    id: 'reports.dataExport.customFieldsPlaceholder',
    defaultMessage: 'Custom fields'
  },
  btnClose: {
    id: 'reports.dataExport.btnClose',
    defaultMessage: 'Klar'
  },
  more: {
    id: 'reports.dataExport.more',
    defaultMessage: 'more'
  },
  reportingDate: {
    id: 'reports.dataExport.reportingDate',
    defaultMessage: 'Reporting date'
  },
  reportingDateStart: {
    id: 'reports.dataExport.reportingDateStart',
    defaultMessage: 'Reporting date start'
  },
  reportingDateCreated: {
    id: 'reports.dataExport.reportingDateCreated',
    defaultMessage: 'Reporting date created'
  },
  selectAll: {
    id: 'reports.dataExport.selectAll',
    defaultMessage: 'Select All'
  },
  deselectAll: {
    id: 'reports.dataExport.deselectAll',
    defaultMessage: 'Deselect All'
  },
  selectAllFiltered: {
    id: 'reports.dataExport.selectAllFiltered',
    defaultMessage: 'Select Filtered'
  },
  deselectAllFiltered: {
    id: 'reports.dataExport.deselectAllFiltered',
    defaultMessage: 'Deselect Filtered'
  },
  fileFormat: {
    id: 'reports.dataExport.fileFormat',
    defaultMessage: 'File format'
  },
  preview: {
    id: 'reports.dataExport.preview',
    defaultMessage: 'Preview'
  },
  noDataFound: {
    id: 'reports.dataExport.noDataFound',
    defaultMessage: 'No data found'
  },
  reportPreview: {
    id: 'reports.dataExport.reportPreview',
    defaultMessage: 'Report preview'
  },
  rowsLimit: {
    id: 'reports.dataExport.rowsLimit',
    defaultMessage: 'Only showing first 100 rows'
  },
  download: {
    id: 'reports.dataExport.download',
    defaultMessage: 'Download'
  },
  locName: {
    id: 'reports.dataExport.locName',
    defaultMessage: 'Location name'
  },
  bookingId: {
    id: 'reports.dataExport.bookingId',
    defaultMessage: 'Booking ID'
  },
  bookingRef: {
    id: 'reports.dataExport.bookingRef',
    defaultMessage: 'Booking reference'
  },
  createdTime: {
    id: 'reports.dataExport.createdTime',
    defaultMessage: 'Created time'
  },
  startTime: {
    id: 'reports.dataExport.startTime',
    defaultMessage: 'Start time'
  },
  status: {
    id: 'reports.dataExport.status',
    defaultMessage: 'Status'
  },
  name: {
    id: 'reports.dataExport.name',
    defaultMessage: 'Name'
  },
  serviceName: {
    id: 'reports.dataExport.serviceName',
    defaultMessage: 'Service name'
  },
  price: {
    id: 'reports.dataExport.price',
    defaultMessage: 'Price'
  },
  resourceName: {
    id: 'reports.dataExport.resourceName',
    defaultMessage: 'Resource name'
  },
  uuid: {
    id: 'reports.dataExport.uuid',
    defaultMessage: 'UUID'
  },
  sequenceNo: {
    id: 'reports.dataExport.sequenceNo',
    defaultMessage: 'Sequence No'
  },
  locationId: {
    id: 'reports.dataExport.locationId',
    defaultMessage: 'Location ID'
  },
  resourceId: {
    id: 'reports.dataExport.resourceId',
    defaultMessage: 'Resource ID'
  },
  cancelledTime: {
    id: 'reports.dataExport.cancelledTime',
    defaultMessage: 'Cancelled time'
  },
  lastUpdateTime: {
    id: 'reports.dataExport.lastUpdateTime',
    defaultMessage: 'Last update time'
  },
  endTime: {
    id: 'reports.dataExport.endTime',
    defaultMessage: 'End time'
  },
  channel: {
    id: 'reports.dataExport.channel',
    defaultMessage: 'Channel'
  },
  cancelledChannel: {
    id: 'reports.dataExport.cancelledChannel',
    defaultMessage: 'Cancelled channel'
  },
  type: {
    id: 'reports.dataExport.type',
    defaultMessage: 'Type'
  },
  reservationType: {
    id: 'reports.dataExport.reservationType',
    defaultMessage: 'Reservation type'
  },
  timeZone: {
    id: 'reports.dataExport.timeZone',
    defaultMessage: 'Time zone'
  },
  description: {
    id: 'reports.dataExport.description',
    defaultMessage: 'Description'
  },
  afterTime: {
    id: 'reports.dataExport.afterTime',
    defaultMessage: 'After time'
  },
  note: {
    id: 'reports.dataExport.note',
    defaultMessage: 'Note'
  },
  customerId: {
    id: 'reports.dataExport.customerId',
    defaultMessage: 'Customer ID'
  },
  companyId: {
    id: 'reports.dataExport.companyId',
    defaultMessage: 'Company ID'
  },
  customerName: {
    id: 'reports.dataExport.customerName',
    defaultMessage: 'Customer name'
  },
  customerPhoneNumber: {
    id: 'reports.dataExport.customerPhoneNumber',
    defaultMessage: 'Customer phone number'
  },
  customerOtherPhoneNumber: {
    id: 'reports.dataExport.customerOtherPhoneNumber',
    defaultMessage: 'Customer other phone number'
  },
  customerEmail: {
    id: 'reports.dataExport.customerEmail',
    defaultMessage: 'Customer email'
  },
  customerBookedAs: {
    id: 'reports.dataExport.customerBookedAs',
    defaultMessage: 'Customer booked as'
  },
  customerPersonalIDNo: {
    id: 'reports.dataExport.customerPersonalIDNo',
    defaultMessage: 'Customer personal number'
  },
  customerDeleted: {
    id: 'reports.dataExport.customerDeleted',
    defaultMessage: 'Customer deleted'
  },
  personalIDNo: {
    id: 'reports.dataExport.personalIDNo',
    defaultMessage: 'Personal ID No'
  },
  dropIn: {
    id: 'reports.dataExport.dropIn',
    defaultMessage: 'Drop in'
  },
  askedForPerson: {
    id: 'reports.dataExport.askedForPerson',
    defaultMessage: 'Asked for person'
  },
  bookingPrice: {
    id: 'reports.dataExport.bookingPrice',
    defaultMessage: 'Booking price'
  },
  isPriceFrom: {
    id: 'reports.dataExport.isPriceFrom',
    defaultMessage: 'Is price from'
  },
  pending: {
    id: 'reports.dataExport.pending',
    defaultMessage: 'Pending'
  },
  pendingUntil: {
    id: 'reports.dataExport.pendingUntil',
    defaultMessage: 'Pending until'
  },
  reminderStatus: {
    id: 'reports.dataExport.reminderStatus',
    defaultMessage: 'Reminder status'
  },
  bookedAs: {
    id: 'reports.dataExport.bookedAs',
    defaultMessage: 'Booked as'
  },
  reminderStatusUpdated: {
    id: 'reports.dataExport.reminderStatusUpdated',
    defaultMessage: 'Reminder status updated'
  },
  orgNo: {
    id: 'reports.dataExport.orgNo',
    defaultMessage: 'Organisation no'
  },
  orgName: {
    id: 'reports.dataExport.orgName',
    defaultMessage: 'Organisation name'
  },
  vehicleRegNo: {
    id: 'reports.dataExport.vehicleRegNo',
    defaultMessage: 'Vehicle reg no'
  },
  vehicleDescription: {
    id: 'reports.dataExport.vehicleDescription',
    defaultMessage: 'Vehicle description'
  },
  serviceIds: {
    id: 'reports.dataExport.serviceIds',
    defaultMessage: 'Location service IDs'
  },
  serviceNames: {
    id: 'reports.dataExport.serviceNames',
    defaultMessage: 'Service names'
  },
  servicePrices: {
    id: 'reports.dataExport.servicePrices',
    defaultMessage: 'Service prices'
  },
  serviceIsPriceFroms: {
    id: 'reports.dataExport.serviceIsPriceFroms',
    defaultMessage: 'Service is price froms'
  },
  serviceDurations: {
    id: 'reports.dataExport.serviceDurations',
    defaultMessage: 'Service durations'
  },
  paymentAmount: {
    id: 'reports.dataExport.paymentAmount',
    defaultMessage: 'Payment amount'
  },
  paymentProvider: {
    id: 'reports.dataExport.paymentProvider',
    defaultMessage: 'Payment provider'
  },
  paymentStatus: {
    id: 'reports.dataExport.paymentStatus',
    defaultMessage: 'Payment status'
  },
  amount: {
    id: 'reports.dataExport.amount',
    defaultMessage: 'Amount'
  },
  paymentRef: {
    id: 'reports.dataExport.paymentRef',
    defaultMessage: 'Payment reference'
  },
  paidTs: {
    id: 'reports.dataExport.paidTs',
    defaultMessage: 'Paid timestamp'
  },
  paymentStatusTs: {
    id: 'reports.dataExport.paymentStatusTs',
    defaultMessage: 'Payment status timestamp'
  },
  refundTs: {
    id: 'reports.dataExport.refundTs',
    defaultMessage: 'Refund timestamp'
  },
  attribs: {
    id: 'reports.dataExport.attribs',
    defaultMessage: 'Attributes'
  },
  saleId: {
    id: 'reports.dataExport.saleId',
    defaultMessage: 'Sale ID'
  },
  associatedResourceId: {
    id: 'reports.dataExport.associatedResourceId',
    defaultMessage: 'Associated resource ID'
  },
  ownedByLocation: {
    id: 'reports.dataExport.ownedByLocation',
    defaultMessage: 'Owned by location'
  },
  partnerId: {
    id: 'reports.dataExport.partnerId',
    defaultMessage: 'Partner ID'
  }
});
