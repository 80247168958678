import CurrencyUtil from './currency-util';

export function getDurationAndPrice(service) {
  const { serviceDuration, afterTime, price, priceFrom } = service;

  const duration = serviceDuration || 0;
  const durationStr = afterTime > 0 ? `${duration} min + ${afterTime} min paus` : `${duration} min`;
  const priceStr = (priceFrom ? 'Från ' : '') + CurrencyUtil.accountCurrency(price, 0);

  const isPriceSet = price !== null && price !== undefined;
  return [durationStr, isPriceSet && priceStr].filter(i => i).join(', ');
}
