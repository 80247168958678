import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { fetchBusinessCategories } from '@State/sysadmin-actions';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import HookFormInput from '@Components/inputs/hook-form-input';

const AccountBusinessCategoryForm = ({ onClose, onSubmit, initialValues, loading }) => {
  const dispatch = useDispatch();
  const methods = useForm({ defaultValues: initialValues });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    dispatch(fetchBusinessCategories())
      .then((data) => setCategories(data.category));
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <HookFormInput
          type="select"
          id="category"
          label="Kategori"
          registerOptions={{ required: true }}
          options={[
            { value: '', title: 'Välj kategori' },
            ...categories.map((category) => ({
              value: category.label, title: category.label
            }))
          ]}
        />
        <FormButtonsFooter
          onClose={onClose}
          handleSubmit={methods.handleSubmit}
          submitSucceeded={false}
          submitting={loading}
        />
      </form>
    </FormProvider>
  );
};

export default AccountBusinessCategoryForm;
