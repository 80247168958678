import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setBKFAttributes } from '@State/bkf/actions';
import { getReservationTypes } from '@State/selectors';
import bookingUtilMsg from '@Utils/booking-util.msg';
import { txt } from '@Utils/i18n-util';

class BookingReservationType extends Component {
  handleLabelClick = (value) => {
    const { reservationType } = this.props;
    const newValue = value !== reservationType ? value : null;
    this.props.setReservationType(newValue);
  };

  render() {
    const { reservationType, reservationTypes } = this.props;
    return (
      <div className="booking-form-labels">
        {reservationTypes.map((type) => {
          const className = reservationType === type ? 'btn-label selected' : 'btn-label';
          return (
            <button key={type} className={className} onClick={ev => this.handleLabelClick(type)}>
              {bookingUtilMsg[type] ? txt(bookingUtilMsg[type]) : type}
            </button>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;
  const { reservationType } = bkf.get('attributes') || {};

  return {
    reservationType,
    reservationTypes: getReservationTypes(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReservationType: (reservationType) => {
      dispatch(setBKFAttributes({ reservationType }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingReservationType);
