import React from 'react';
import { useSelector } from 'react-redux';
import { bkfModals } from '@State/bkf/constants';
import CustomerModal from '@Components/customers/customer-modal';
import VehicleModal from '@Components/calendar/booking/vehicle-modal';
import BookingEventsModal from '@Components/calendar/booking/booking-events-modal';
import BookingPaymentModal from '@Components/calendar/booking/booking-payment-modal';
import BookingPrintModal from '@Components/calendar/booking/booking-print-modal';

const BookingModals = ({ routeParams }) => {
  const showModal = useSelector(state => state.bkf.get('showModal'));
  return (
    <>
      <CustomerModal routeParams={routeParams} />
      {showModal === bkfModals.print && <BookingPrintModal />}
      {showModal === bkfModals.vehicle && <VehicleModal />}
      {showModal === bkfModals.events && <BookingEventsModal />}
      {showModal === bkfModals.payment && <BookingPaymentModal />}
    </>
  );
};

export default BookingModals;
