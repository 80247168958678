export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const NETWORK_FAILED = 'NETWORK_FAILED';
export const LOADING_DONE = 'LOADING_DONE';
export const VERSION_MISMATCH = 'VERSION_MISMATCH';

export function loading() {
  return {
    type: LOADING
  };
}

export function loadingDone() {
  return {
    type: LOADING_DONE
  };
}

export function networkFailure(message, title, details) {
  return {
    type: NETWORK_FAILED,
    title: title || 'Ett fel inträffade',
    message: message || 'Ett fel inträffade vid kontakt med servern. Försök igen eller kontakta oss om felet kvarstår.',
    details
  };
}

export function resetNetworkFailure() {
  return {
    type: NETWORK_FAILED,
    message: null
  };
}

export function success(message) {
  return {
    type: SUCCESS,
    message
  };
}

export function versionMismatch(currentVersion, requiredVersion) {
  return {
    type: VERSION_MISMATCH,
    state: {
      currentVersion,
      requiredVersion
    }
  };
}
