import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import {
  prefixV2Url, axiosDefault, checkStatusAxios, axiosErrorHandler
} from '@Utils/ajax-util';
import { viewDateToStartEnd } from '@Utils/time-util';

import { getResourcesInView } from '@State/calendar-selectors';
import {
  RESET_FINDTIME,
  ADD_FINDTIME_SERVICE,
  REMOVE_FINDTIME_SERVICE,
  SET_FINDTIME_SERVICES,
  ADD_FINDTIME_RESOURCE,
  REMOVE_FINDTIME_RESOURCE,
  SET_FINDTIME_RESOURCES,
  SET_FINDTIME_SLOTS,
  RESET_FINDTIME_SLOTS
} from './constants';

export function resetFindTime() {
  return { type: RESET_FINDTIME };
}

export function addFindTimeService(service) {
  return { type: ADD_FINDTIME_SERVICE, service };
}

export function removeFindTimeService(service) {
  return { type: REMOVE_FINDTIME_SERVICE, service };
}

export function setFindTimeServices(services) {
  return { type: SET_FINDTIME_SERVICES, services };
}

export function addFindTimeResource(resource) {
  return { type: ADD_FINDTIME_RESOURCE, resource };
}

export function removeFindTimeResource(resource) {
  return { type: REMOVE_FINDTIME_RESOURCE, resource };
}

export function setFindTimeResources(resources) {
  return { type: SET_FINDTIME_RESOURCES, resources };
}

export function setFindTimeSlots(slots, services, resources, showInCalendar) {
  return { type: SET_FINDTIME_SLOTS, slots, services, resources, showInCalendar };
}

export function resetFindTimeSlots() {
  return { type: RESET_FINDTIME_SLOTS };
}

export function fetchAvailableSlots(routeParams, showInCalendar) {
  return (dispatch, getState) => {
    const state = getState();
    const services = state.findTime.get('services');
    const resources = getResourcesInView(state, { routeParams });
    const { viewMode, viewDate } = routeParams;
    const { start, end } = viewDateToStartEnd(viewMode, viewDate);

    const query = queryString.stringify({
      srvIds: services.map(s => s.id).join(','),
      resIds: resources.map(s => s.id).join(','),
      fromDate: start.format('YYYY-MM-DD'),
      toDate: end.subtract(1, 'd').format('YYYY-MM-DD'),
      includeNonWebBookable: true,
      includeNonAvailableMRSlots: true,
      dontDedupeMRSlots: true
    });

    const url = prefixV2Url(`/resources/slots?${query}`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ resourceSlots }) => {
        const slots = resourceSlots?.reduce((s, r) => s.concat(r.slots), []);
        dispatch(setFindTimeSlots(slots, services, resources, showInCalendar));
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function getBookingFromSlot(slot, services, resources) {
  const { key, date, time, length, resourceId } = slot;
  const startTime = moment(`${date}T${time}`);
  const endTime = moment(startTime).add(length, 'minutes');

  return {
    id: key,
    resourceId,
    // customerName: atob(key),
    resources: [{ id: resourceId }],
    // services: services.valueSeq().toJS(),
    startTime,
    endTime,
    type: 'SimpleBooking',
    status: 'Booked',
    channel: 'Cal',
    pending: false,
    reservation: false,
    timeSlot: true
  };
}
