import Webcam from 'react-webcam';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@Components/ui/button';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Column from '@Components/ui/styled/column';
import Popover from '@Components/ui/popover';

const WebcamModal = ({ onClose, onSubmit }) => {
  const webcamRef = React.useRef(null);
  const [image, setImage] = useState(null);
  const [devices, setDevices] = useState([]);
  const [showDevices, setShowDevices] = useState(false);
  const [deviceId, setDeviceId] = useState(localStorage.getItem('webcamDeviceId'));
  const hasMultipleDevices = devices.length > 1;

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices()
      .then(mediaDevices => {
        setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput'));
      });
  }, []);

  const videoConstraints = useMemo(() => {
    return deviceId ? { deviceId } : { facingMode: 'environment' };
  }, [deviceId]);

  const switchDevice = (deviceId) => {
    localStorage.setItem('webcamDeviceId', deviceId);
    setDeviceId(deviceId);
    setShowDevices(false);
  };

  const captureImage = () => {
    setImage(webcamRef.current.getScreenshot());
  };

  const submitImage = () => {
    onSubmit(image);
    onClose();
  };

  if (image) {
    return (
      <ModalDialog
        contentSize="xl"
        closeButtonText="Stäng"
        closeOnClickOutside={false}
        onClose={onClose}
        title="Ta bild"
      >
        <img src={image} />
        <div className="mt4 text-center">
          <Button large primary marginRight onClick={submitImage}>
            Använd bild
          </Button>
          <Button large gray onClick={() => setImage(null)}>
            Ta ny bild
          </Button>
        </div>
      </ModalDialog>
    );
  }

  const renderPopupBody = (
    <div className="Popover-dialog-sm button-wrapper">
      <Column className="menu-button-wrapper">
        {devices.map(device => (
          <Button small gray block onClick={() => switchDevice(device.deviceId)}>
            {device.label.length > 22
              ? `${device.label.substring(0, 22)}...`
              : device.label}
          </Button>
        ))}
      </Column>
    </div>
  );

  return (
    <ModalDialog
      contentSize="xl"
      closeButtonText="Stäng"
      closeOnClickOutside={false}
      focusTrapPaused={showDevices}
      onClose={onClose}
      title="Ta bild"
    >
      <Webcam
        audio={false}
        ref={webcamRef}
        forceScreenshotSourceSize
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <div className="mt4 text-center">
        <Button large primary marginRight={hasMultipleDevices} onClick={captureImage}>
          <i className="far fa-camera mr1" />
          Ta bild
        </Button>
        {hasMultipleDevices && (
          <Popover
            preferPlace="above"
            isOpen={showDevices}
            onOuterAction={() => setShowDevices(false)}
            body={renderPopupBody}
            className="Popover-big-radius"
          >
            <Button large gray onClick={() => setShowDevices(true)}>
              Välj kamera
            </Button>
          </Popover>
        )}
      </div>
    </ModalDialog>
  );
};

export default WebcamModal;
