import React, { PureComponent } from 'react';
import { styles, getVisualStyles } from './chip-styles';
import { getIcons } from './chip-utils';

class ChipIcons extends PureComponent {
  label(label) {
    const { highContrast } = this.props;
    const visualStyles = getVisualStyles(highContrast);
    const lblStyle = {
      ...styles.label,
      ...visualStyles[label]
    };
    return this.props[label] ? <div style={lblStyle} /> : '';
  }

  render() {
    const { sales, showPaidBookingBadge } = this.props;
    const icons = getIcons(this.props);
    const paid = sales?.length > 0;

    if (paid && showPaidBookingBadge) {
      return (
        <div className="chip-paid-badge" title="Betald">
          <span className="chip-paid-badge-icon">€</span>
        </div>
      );
    }

    return (
      <div className="pull-right">
        {icons.map(({ icon }) => <i key={icon} className={icon} style={styles.icon} />)}
        {this.label('dropIn')}
        {this.label('askedForPerson')}
      </div>
    );
  }
}

export default ChipIcons;
