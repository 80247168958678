import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import Immutable, { fromJS } from 'immutable';

import authState from '@Login/reducers';

import {
  customerBookings,
  customerById,
  customerGiftCards,
  customerLogEntries,
  customers,
  customerSales
} from '@State/customer-reducer';
import { findTime } from '@State/find-time/reducer';
import { bkf, resourceServices } from '@State/bkf/reducer';
import schedulesByResource, { adminSchedules } from '@State/schedule-reducer';
import { cf } from '@State/cf-reducer';
import {
  pos, posReceipt, posReceipts, posReport, posReports, posSale
} from '@State/pos-reducer';
import { productGroups, products } from '@State/products-reducer';
import { stockTakes, stockTakeItems } from '@State/stock-taking-reducer';
import { deliveries, deliveryItems } from '@State/delivery-reducer';
import {
  orderedServiceGroups, resourceServiceIds, resourceServiceMappingsByCombinedId,
  addonServiceMappingsById, servicesById
} from '@State/services-reducer';
import {
  allTerminals, posContacts, posOrgs, posTerminals, posUnits, allPosUnits, printers, allOnpremPosUnits
} from '@State/pos-config-reducer';
import { giftCards } from '@State/gift-card-reducer';
import { vouchers } from '@State/voucher-reducer';
import { voucherTemplates } from '@State/voucher-templates-reducer';
import { invoices } from '@State/invoice-reducer';
import { orderedGroups, resourcesById } from '@State/resource-reducer';
import {
  cashiersById, invitesById, userPosRoles, usersById
} from '@State/users-reducer';
import { adminViewState } from '@State/admin-view-reducer';
import { adminConfigState, partnersById } from '@State/admin-config-reducer';
import { staffReportState, staffState, staffUserEntriesState } from '@State/staff-reducer';
import { reportsViewState } from '@State/report-reducer';
import { campaignViewState } from '@State/campaign-reducer';
import {
  sysadminControl,
  sysadminInvoice,
  sysadminLocationHardwarePricing,
  sysadminLocationsById,
  sysadminPricePlansById,
  sysadminOrganizationById,
  sysadminOrganizationsState,
  sysadminReleaseNotes,
  sysadminLogEntries
} from '@State/sysadmin-reducer';
import {
  bookingsById, bookingsClipboard, bookingSearchResults, clipboardState
} from '@State/booking-reducers';

import { CUSTOMER_UPDATED } from '@State/customer-actions';
import { SET_SCHEDULE_BLOCKS } from '@State/schedule-constants';
import { calendar } from '@Utils/preference-keys';
import reportsData from '@State/report-data-reducer';
import {
  ADD_BOOKING,
  CANCEL_BOOKING,
  DELETE_BOOKING,
  SET_UNDOABLE_BOOKING
} from './booking-actions';
import {
  LOADING_VIEWDATA,
  RECEIVE_VIEWDATA,
  SELECT_DATE,
  SET_CALENDAR_ROWS_PER_HOUR,
  SET_EXTERNAL_KEYBOARD,
  SET_GRID_SIZE,
  SET_HIGH_CONTRAST,
  SET_SHOW_CHIP_TOOLTIP,
  STAFF_JOURNAL_STATUS_FETCHED,
  STORE_VIEW_STATE,
  TOGGLE_GRID_SCROLLABILITY,
  TOGGLE_GRIDMARKER,
  TOGGLE_SCHEDULE_EDIT_MODE,
  TOGGLE_MULTI_RESOURCE_MODE,
  UPDATE_DIMENSIONS,
  UPDATE_GRIDMARKER,
  TOGGLE_RESOURCE_LIST_COLLAPSED,
  HIGHLIGHT_RESOURCE_BOOKINGS
} from './view-actions';
import {
  CHANGE_PASSWORD,
  SET_CLIENT_PREF,
  SET_PASSWORD_POLICY
} from './user-actions';
import { PASTE_BOOKING } from './clipboard-actions';
import { NETWORK_FAILED, VERSION_MISMATCH } from './network-actions';
import {
  ACCOUNT_STATUS,
  CLEAR_LOCATION_STATE,
  FEATURES_LOADED,
  LOCATION_NAMES_LOADED,
  LOADING_CONFIG,
  LOC_CONFIG_CHANGED,
  RECEIVE_LOC_OPTIONS
} from './account-actions';
import {
  CHANGE_CONTACT_INFO,
  CHANGE_PREFERENCES,
  PREFERENCES_LOADED,
  RESET_RESOURCE_PREFERENCES
} from './preferences-actions';
import { SET_LOCATION_FEATURE } from './features-actions';

function appState(
  state = Immutable.Map({
    currentVersion: 0,
    requiredVersion: 0,
    trialUntil: null,
    trialStatus: '',
    accountStatus: 'Active',
    networkError: null,
    features: ''
  }),
  action = null
) {
  switch (action.type) {
    case ACCOUNT_STATUS:
    case VERSION_MISMATCH:
      return state.merge(action.state);

    case NETWORK_FAILED: {
      const { message, title, details } = action;
      return state.set('networkError', message ? { message, title, details } : null);
    }

    default:
      return state;
  }
}

function mainViewState(
  state = Immutable.Map({
    phoneMode: false,
    tabletMode: false,
    forceUpdateTs: new Date(),
    deviceType: '' // Look at initial state for default setting
  }),
  action = null
) {
  switch (action.type) {
    case STAFF_JOURNAL_STATUS_FETCHED:
      return state.set('staffJournalStatus', action.checkedIn);
    case UPDATE_DIMENSIONS:
      return state.merge({
        phoneMode: action.phoneMode,
        tabletMode: action.tabletMode,
        orientation: action.orientation,
        forceUpdateTs: new Date()
      });
    default:
      return state;
  }
}

function gridViewState(
  state = Immutable.Map({
    pixelsPerRow: 15,
    rowsPerHour: 6,
    gridMarkerDuration: 60,
    gridClientWidth: 500,
    gridClientHeight: 500,
    highContrast: false,
    largeCalendar: false,
    showGridMarker: false,
    clipBoardDragger: null,
    scheduleEditMode: false,
    undoableBooking: null,
    externalKeyboard: false,
    scrollBars: false,
    scrollBarWidth: 0,
    gridSize: 'small',
    gridScrollable: true, // if set to true, prevent scrolling of the grid view, when popups are open etc..,
    draggerVisible: false
  }),
  action = null
) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.merge({
        undoableBooking: null
      });
    case CHANGE_PREFERENCES:
      if (
        action.state.jsonPreferences
        && action.state.jsonPreferences[calendar.rowsPerHour]
      ) {
        return state.merge({
          rowsPerHour: parseInt(action.state.jsonPreferences[calendar.rowsPerHour])
        });
      }
      return state;

    case LOC_CONFIG_CHANGED:
      if (action.state[calendar.rowsPerHour]) {
        return state.merge({
          rowsPerHour: parseInt(action.state[calendar.rowsPerHour])
        });
      }
      return state;

    case TOGGLE_GRID_SCROLLABILITY:
      return state.merge({
        gridScrollable: action.scrollable
      });

    case TOGGLE_SCHEDULE_EDIT_MODE:
      return state.merge({
        undoableBooking: null,
        scheduleEditMode: action.state
      });

    case UPDATE_DIMENSIONS:
      return state.merge({
        gridClientWidth: action.gridClientWidth,
        gridClientHeight: action.gridClientHeight
      });

    case SET_HIGH_CONTRAST:
      return state.merge({
        highContrast: action.enabled
      });

    case SET_SHOW_CHIP_TOOLTIP:
      return state.merge({
        showChipTooltip: action.enabled
      });

    case SET_CALENDAR_ROWS_PER_HOUR:
      return state.merge({
        rowsPerHour: action.rowsPerHour
      });

    case SET_GRID_SIZE:
      return state.merge({
        gridSize: action.size
      });

    case SET_EXTERNAL_KEYBOARD:
      return state.merge({
        externalKeyboard: action.enabled
      });

    case TOGGLE_GRIDMARKER:
      return state.merge({
        showGridMarker: action.state.visible,
        gridMarkerDuration: action.state.duration
      });

    case UPDATE_GRIDMARKER: {
      return state.merge({
        gridMarkerDuration: action.update.duration
      });
    }

    case HIGHLIGHT_RESOURCE_BOOKINGS: {
      return action.resourceIds
        ? state.set('highlightResourceIds', action.resourceIds)
        : state.delete('highlightResourceIds');
    }

    case SET_UNDOABLE_BOOKING:
      return state.merge({
        undoableBooking: action.booking
      });

    case SELECT_DATE:
    case RECEIVE_VIEWDATA:
      return state.merge({
        undoableBooking: null
      });

    case ADD_BOOKING:
    case DELETE_BOOKING:
    case PASTE_BOOKING:
    case CANCEL_BOOKING:
    case CUSTOMER_UPDATED:
    case SET_SCHEDULE_BLOCKS: {
      if (action.type === 'ADD_BOOKING' && action.booking.id === 'DRAGGER') {
        return state.merge({
          undoableBooking: null,
          draggerVisible: true
        });
      }
      if (action.type === 'DELETE_BOOKING' && action.id === 'DRAGGER') {
        return state.merge({
          undoableBooking: null,
          draggerVisible: false
        });
      }

      return state.merge({
        undoableBooking: null
      });
    }

    default:
      return state;
  }
}

/**
 * viewMode = 'week|day', entityType = 'resource|group',  entityId =  id,  viewDate = '2014-39|2014-09-01'
 * calendarViewState.loadingViewData
 * viewMode and viewDate could be combined into say: 2014-09-12 = day, 2014W39 = week, 2014M09 = month
 *
 */
function calendarViewState(
  state = Immutable.Map({
    lastView: null,
    viewDate: null,
    loadingViewData: false,
    resourceListCollapsedIds: [],
    multiResourceMode: false
  }),
  action = null
) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.merge({
        lastView: null,
        resourceListCollapsedIds: [],
        multiResourceMode: false
      });
    case SELECT_DATE:
      return state.set('viewDate', action.viewDate);

    case STORE_VIEW_STATE:
      return state.set('lastView', action.viewState);

    case LOADING_VIEWDATA:
      return state.set('loadingViewData', true);

    case RECEIVE_VIEWDATA:
      return state.set('loadingViewData', false);

    case TOGGLE_RESOURCE_LIST_COLLAPSED:
      return state.set('resourceListCollapsedIds', action.collapsedIds);

    case TOGGLE_MULTI_RESOURCE_MODE:
      return state.set('multiResourceMode', action.state);

    default:
      return state;
  }
}

function userConfigViewState(state = Immutable.Map({
  passwordPolicy: null,
  wrongPassword: false,
  changePasswordSuccess: false,
  isChangingPassword: false
}), action = null) {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return state.merge(action.state);

    case SET_PASSWORD_POLICY:
      return state.merge({ passwordPolicy: action.passwordPolicy });

    default:
      return state;
  }
}

function preferencesViewState(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case PREFERENCES_LOADED:
      return state.set('jsonPreferencesLoaded', true)
        .set('jsonPreferences', Immutable.fromJS(action.state.jsonPreferences));

    case CHANGE_PREFERENCES:
      return state.mergeDeep(action.state);

    case CHANGE_CONTACT_INFO: {
      if (action.contact) {
        return state.setIn(
          ['contacts', action.contactType],
          Immutable.Map(action.contact)
        );
      }
      return state.deleteIn(['contacts', action.contactType]);
    }

    case RESET_RESOURCE_PREFERENCES:
      return state.delete('resourcePreferences');

    default:
      return state;
  }
}

function userClientPreferences(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case SET_CLIENT_PREF:
      return state.mergeDeep(action.state);
    default:
      return state;
  }
}

function locationConfig(
  state = Immutable.Map({}),
  action = null
) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case LOC_CONFIG_CHANGED:
      return state.mergeDeep(action.state);

    case CHANGE_PREFERENCES:
      if (action.state.jsonPreferences) {
        return state.mergeDeep(action.state.jsonPreferences);
      }
      return state;

    default:
      return state;
  }
}

function locationFeatures(
  state = Immutable.Map({
    state: 'NOT_LOADED'
  }),
  action = null
) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear().set('state', 'NOT_LOADED');

    case LOADING_CONFIG:
      return state.clear().set('state', 'LOADING');

    case FEATURES_LOADED:
      return state.mergeDeep(action.features).set('state', 'LOADED');

    case SET_LOCATION_FEATURE:
      return state.set(action.name, action.enabled);

    default:
      return state;
  }
}

function locationNames(
  state = Immutable.Map(),
  action = null
) {
  switch (action.type) {
    case LOCATION_NAMES_LOADED:
      return state.clear().withMutations(map => {
        Object.keys(action.locationNames).forEach(key => {
          map.set(parseInt(key), action.locationNames[key]);
        });
      });

    default:
      return state;
  }
}

function locationOptions(state = Immutable.Map({}), action = null) {
  switch (action.type) {
    case RECEIVE_LOC_OPTIONS:
      return state.clear().withMutations(map => {
        for (const location of action.state.locations) {
          map.set(location.orgLoc, location);
        }
      });

    case CHANGE_PREFERENCES:
      if (action.state.orgLoc && action.state.companyInfo) {
        const { orgName } = action.state.companyInfo;
        const location = state.get(action.state.orgLoc);
        const newLocation = location.orgName === location.locName
          ? { ...location, orgName, locName: orgName }
          : { ...location, orgName };
        return state.set(action.state.orgLoc, newLocation);
      }
      return state;

    default:
      return state;
  }
}

const rootReducer = combineReducers({
  appState,
  authState,
  orderedGroups,
  resourcesById,
  bookingsById,
  bookingSearchResults,
  bookingsClipboard,
  clipboardState,
  schedulesByResource,
  calendarViewState,
  mainViewState,
  gridViewState,
  reportsViewState,
  campaignViewState,
  userConfigViewState,
  preferencesViewState,
  userClientPreferences,
  form: formReducer,
  locationConfig,
  locationOptions,
  locationFeatures,
  locationNames,
  bkf,
  findTime,
  resourceServices,
  cf,
  servicesById,
  orderedServiceGroups,
  resourceServiceIds,
  resourceServiceMappingsByCombinedId,
  addonServiceMappingsById,
  usersById,
  cashiersById,
  invitesById,
  partnersById,
  userPosRoles,
  pos,
  products,
  productGroups,
  stockTakes,
  stockTakeItems,
  deliveries,
  deliveryItems,
  posOrgs,
  posContacts,
  posUnits,
  allPosUnits,
  posSale,
  posReceipt,
  posReceipts,
  posReport,
  posReports,
  posTerminals,
  reportsData,
  allTerminals,
  allOnpremPosUnits,
  printers,
  giftCards,
  vouchers,
  voucherTemplates,
  invoices,
  customers,
  customerById,
  customerSales,
  customerGiftCards,
  customerLogEntries,
  customerBookings,
  adminViewState,
  adminSchedules,
  adminConfigState,
  staffState,
  staffReportState,
  staffUserEntriesState,
  sysadminOrganizationsState,
  sysadminInvoice,
  sysadminOrganizationById,
  sysadminControl,
  sysadminLocationHardwarePricing,
  sysadminLocationsById,
  sysadminPricePlansById,
  sysadminReleaseNotes,
  sysadminLogEntries
});

export default rootReducer;
