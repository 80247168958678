import moment from 'moment/moment';
import { createSelector } from 'reselect';
import { pick } from 'ramda';
import { txt } from '@Utils/i18n-util';
import { isBookingCancelled, isBookingNew, isSimpleBooking } from '@Utils/booking-util';
import { formatPhoneNumberE164 } from '@Utils/phone-util';
import msg from './booking-form.msg';

export function getBookingTitle(isNew, isReservation, isSchedule) {
  if (isReservation) {
    return txt(msg.titleBlockTime);
  }
  if (isSchedule) {
    return txt(msg.titleSchedule);
  }
  return isNew ? txt(msg.titleTextNew) : txt(msg.titleTextChange);
}

export function getSaveBtnText(exceptionType, isSaving) {
  if (exceptionType) {
    return exceptionType === 'open' ? txt(msg.openSchedule) : txt(msg.closeSchedule);
  }
  return isSaving ? txt(msg.savingText) : txt(msg.saveText);
}

export function shouldSendConfirmations(props, initialState) {
  const { customer, startTime, resourceId } = props;

  const isNew = customer && isBookingNew(props);
  const isBooking = isSimpleBooking(props);
  const isCancelled = isBookingCancelled(props);
  const isPastBooking = isBooking && moment().isAfter(startTime);

  const customerDetailsChanged = isCustomerDetailsChanged(customer);
  const isMoved = !!(
    initialState
      && (initialState.startTime !== startTime.toISOString()
        || initialState.resourceId !== resourceId)
  ) && customer;

  return !!(
    isBooking
    && !isCancelled
    && !isPastBooking
    && (isNew || isMoved || customerDetailsChanged)
  );
}

function isCustomerDetailsChanged(customer, initialState) {
  return !!(
    initialState
    && customer
    && (initialState.customerId !== customer.customerId
      || initialState.customerPhoneNumber !== formatPhoneNumberE164(customer.phoneNumber)
      || initialState.customerEmail !== customer.email)
  );
}

function getCompany(props) {
  const { company, companyForm, enableCompanyBooking } = props;
  const companyId = company && company.companyId;
  const { orgNo, orgName } = companyForm || company || {};

  return enableCompanyBooking ? {
    companyId,
    orgNo,
    orgName
  } : null;
}

export function getCustomer(props) {
  const { customer, customerForm } = props;
  const customerId = customer && customer.customerId;
  const {
    name, phoneNumber, otherPhoneNumber, email, pno
  } = customerForm || customer || {};

  return {
    customerId,
    customerName: name,
    customerPhoneNumber: formatPhoneNumberE164(phoneNumber),
    customerOtherPhoneNumber: formatPhoneNumberE164(otherPhoneNumber),
    customerEmail: email,
    customerPno: pno
  };
}

export function getService(props) {
  const { price } = props.priceForm || props.service || {};
  const { name } = props.serviceForm || props.service || {};
  const { serviceDuration, afterTime } = props.timeForm || props.service || {};
  const { startTime, endTime } = props;

  return {
    name: name || null,
    afterTime: parseInt(afterTime),
    serviceDuration: parseInt(serviceDuration),
    totalDuration: endTime.diff(startTime, 'minutes'),
    price: parseInt(price)
  };
}

export const getServiceInitialValues = createSelector(
  state => state.bkf.get('service'),
  state => state.bkf.get('services'),
  (service, services) => {
    const initialValues = { ...service };
    services && services.valueSeq().forEach(s => {
      initialValues[`servicePrice${s.id}`] = s.price;
    });
    return initialValues;
  }
);

export function getServiceProps(service, services) {
  const mapService = s => ({
    id: s.id,
    name: s.name,
    duration: s.duration || s.serviceDuration,
    price: s.price,
    priceFrom: s.priceFrom
  });

  const hasServices = services && services.size > 0;
  const nameChanged = services && services.size === 1 && services.first().name !== service.name;
  const saveDescription = !hasServices || nameChanged;

  return {
    services: services ? [...services.valueSeq().map(mapService)] : [],
    description: saveDescription ? service.name : undefined,
    price: service.price,
    afterTime: service.afterTime
  };
}

function getSecondaryResources(resources) {
  return resources.valueSeq()
    .filter(r => !r.primary).map(r => r.id);
}

function getAttributes(props) {
  const { attributes, vehicle } = props;
  const include = ['colorway', 'chipTmplId', 'fub_bkId', 'imageUrl'];

  return {
    ...pick(include, attributes),
    ...vehicle?.vehicleAttributes
  };
}

function getCustomFields(props) {
  const { customFields, customFieldsForm, customValues } = props;
  const values = customFieldsForm || customValues || {};

  if (!customFields || customFields.length === 0) {
    return null;
  }

  const fields = [];
  customFields.forEach(({ key, type, label }) => {
    fields.push({
      key, type, label, value: values[key]
    });
  });
  return fields;
}

function getVehicle(props) {
  const { vehicleForm, vehicle, enableVehicleBooking } = props;
  const { vehicleRegNo, vehicleDescription } = vehicleForm || vehicle || {};

  return enableVehicleBooking ? {
    vehicleRegNo,
    vehicleDescription
  } : {};
}

function getNotes(props) {
  const { notesForm, attributes } = props;
  const { note } = notesForm || attributes || {};
  return note;
}

function getReservationType(props) {
  const { attributes } = props;
  const { type, reservationType } = attributes || {};
  const isBooking = type === 'SimpleBooking';

  return isBooking ? null : reservationType;
}

function getEndTime(service, props) {
  const totalDuration = service ? service.serviceDuration + service.afterTime : 0;
  const newEndTime = moment(props.startTime).add(totalDuration, 'minutes');

  return totalDuration > 0 ? newEndTime : props.endTime;
}

export function getConfirmations(props, initialState) {
  const { sendSmsConfirmation, sendEmailConfirmation } = props.confirmationForm || {};

  return shouldSendConfirmations(props, initialState) ? {
    sms: sendSmsConfirmation,
    email: sendEmailConfirmation
  } : {};
}

export function getBookingData(props) {
  const { id, startTime, resourceId, attributes: { type } } = props;
  const isBooking = isSimpleBooking(props);

  const vehicle = isBooking ? getVehicle(props) : null;
  const company = isBooking ? getCompany(props) : null;
  const customer = isBooking ? getCustomer(props) : null;
  const service = getService(props);
  const endTime = getEndTime(service, props);
  const serviceProps = getServiceProps(service, props.services);

  return {
    id: isBookingNew(props) ? undefined : id,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    resourceId,
    type,
    ...vehicle,
    ...company,
    ...customer,
    ...serviceProps,
    note: getNotes(props),
    attributes: getAttributes(props),
    customFields: getCustomFields(props),
    reservationType: getReservationType(props),
    secondaryResources: getSecondaryResources(props.resources)
  };
}

export function getScheduleData(props) {
  const { startTime, endTime, resourceId, attributes } = props;
  const { type, exceptionType, openType } = attributes || {};

  return {
    startTime,
    endTime,
    resourceId,
    type,
    exceptionType,
    openType,
    comment: getNotes(props)
  };
}

export function updateTimeFromServices(services, props) {
  const { id, startTime, endTime, resourceServices, resourceId } = props;
  const hasServices = services && services.length > 0;
  const resSrvs = resourceServices.get(resourceId);

  const fAfterTime = aSrv => {
    const srv = resSrvs
      ? resSrvs.find(srv => {
        return srv.id === aSrv.id;
      })
      : null;
    return srv ? srv.afterTime : 0;
  };

  const sum = (prev, next) => prev + next;
  const name = hasServices ? services.map(s => s.name).join(', ') : null;
  const price = hasServices ? services.map(s => s.price).reduce(sum) : 0;
  const afterTime = hasServices ? Math.max(...services.map(s => fAfterTime(s))) : 0;
  const serviceDuration = hasServices ? services.map(s => s.duration || s.serviceDuration).reduce(sum) : 0;
  const newEndTime = serviceDuration ? moment(startTime).add(serviceDuration + (afterTime || 0), 'm') : endTime;

  const service = hasServices ? {
    name,
    serviceDuration,
    afterTime,
    price
  } : null;

  const newTime = {
    startTime,
    endTime: newEndTime
  };

  props.onServiceSelect(id, service, newTime);
}

export function updateResourcesFromServices(services, props) {
  const { resources, resourcesById } = props;

  services.forEach(({ multiResourceRules }) => {
    if (multiResourceRules) {
      multiResourceRules.forEach(({ resourceIds }) => {
        const isFulfilled = resourceIds.some(id => resources.has(id));

        if (!isFulfilled && resourceIds.length === 1) {
          props.onResourceAdd({ ...resourcesById.get(resourceIds[0]), isNew: true });
        }
      });
    }
  });
}

export function validateBooking(props) {
  const isBooking = isSimpleBooking(props);
  const company = isBooking ? getCompany(props) : null;
  const errors = [];

  if (company && company.orgName && !(company.orgNo?.length > 0)) {
    errors.push('company.orgNo');
  }

  return errors.length > 0 ? errors : null;
}
