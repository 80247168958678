export function getDescription(description, services) {
  const hasServices = services && services.length > 0;
  const serviceDescriptions = hasServices ? services.map(s => s.name).join(', ') : '';
  return description || serviceDescriptions;
}

export const NEW_BOOKING = 'DRAGGER';
export const isBookingNew = ({ id }) => id === NEW_BOOKING;
export const isBookingCancelled = ({ attributes }) => attributes.status === 'Cancelled';
export const isSimpleBooking = ({ attributes }) => attributes.type === 'SimpleBooking';
export const isBookingReservation = ({ attributes }) => attributes.type === 'Reservation';
export const isScheduleException = ({ attributes }) => attributes.type === 'ScheduleException';

export const scheduleOpenTypes = {
  Normal: 'Normal',
  Overtime: 'Overtime'
};

export const scheduleTimeTypes = {
  Scheduled: 'Schemalagd tid',
  Journaled: 'Närvarotid',
  Overtime: 'Övertid',
  Qualifying: 'Karenstid',
  SickLeave80: 'Sjuk',
  SickChildLeave: 'VAB',
  Vacation: 'Semester',
  OnLeave: 'Tjänstledig',
  Furlough: 'Permission',
  WorkingHoursReduction: 'ATF',
  Absence: 'Frånvaro'
};
