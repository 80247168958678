import React from 'react';
import PropTypes from 'prop-types';
import { DialogButtonsHorizontal, DialogButton } from '@Components/dialogs/dialog-styles';

export default class AddItemForm extends React.Component {
  static propTypes = {
    onClosePopover: PropTypes.func.isRequired,
    onAddItem: PropTypes.func.isRequired,
    groups: PropTypes.object,
    placeholder: PropTypes.string.isRequired,
    infoText: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
      group: props.groups?.first().get('id'),
      type: props.types ? Object.keys(props.types)[0] : null,
      progress: false
    };
  }

  componentDidMount() {
    this.input.focus();
  }

  handleAdd = ev => {
    ev.preventDefault();
    this.setState({ progress: true });
    this.props
      .onAddItem(this.state.group, this.state.name, this.state.type)
      .catch(() => this.setState({ progress: false }));
  };

  render() {
    const { name, group, type, progress } = this.state;
    const { onClosePopover, types, groups, placeholder, submitText, infoText } = this.props;
    const disabled = !name || progress;

    return (
      <div className="Popover-dialog-sm">
        <form autoComplete="off" onSubmit={this.handleAdd}>
          {infoText && (
            <div className="alert alert-sm alert-info text-center">
              {infoText}
            </div>
          )}
          <div className="form-group">
            <label className="control-label">Namn</label>
            <input
              type="text"
              className="form-control"
              placeholder={placeholder}
              ref={ref => {
                this.input = ref;
              }}
              onChange={ev => this.setState({ name: ev.target.value })}
              value={name}
            />
          </div>
          {groups && (
            <div className="form-group">
              <label className="control-label">Grupp</label>
              <div className="select-container">
                <select
                  name="group"
                  className="form-control"
                  value={group}
                  onChange={ev => this.setState({ group: ev.target.value })}
                >
                  {groups.map(group => (
                    <option key={group.get('id')} value={group.get('id')}>
                      {group.get('name')}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {types && (
            <div className="form-group">
              <label className="control-label">Typ</label>
              <div className="select-container">
                <select
                  name="group"
                  className="form-control"
                  value={type}
                  onChange={ev => this.setState({ type: ev.target.value })}
                >
                  {Object.keys(types).map(type => (
                    <option key={type} value={type}>
                      {types[type]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <DialogButtonsHorizontal>
            <DialogButton small gray onClick={onClosePopover}>Avbryt</DialogButton>
            <DialogButton small primary type="submit" disabled={disabled}>{submitText || 'Lägg till'}</DialogButton>
          </DialogButtonsHorizontal>
        </form>
      </div>
    );
  }
}
