import moment from 'moment';
import React from 'react';
import {
  getSortedKeys, getUserOrChannel, historyEventAction,
  historyEventKey, historyEventValue
} from '@Utils/history-util';
import { Panel } from '@Components/ui/styled/main';

const HistoryEvent = ({ eventTs, action, user, channel, data }) => (
  <Panel key={eventTs}>
    <div>
      <span className="text-muted pull-right">{moment(eventTs).format('LLL')}</span>
      <strong>{historyEventAction[action] || action} {getUserOrChannel(user, channel)}</strong>
    </div>
    {Object.keys(data).length > 0 ? (
      <>
        <hr />
        <table className="table table-no-border table-events">
          <tbody>
            {getSortedKeys(data).map((key) => {
              const [from, to] = data[key];
              const fromValue = historyEventValue(key, from, to);
              const toValue = historyEventValue(key, to, from);
              return (
                <tr key={key}>
                  <td width="20%"><span className="text-muted">{String(historyEventKey[key] || key)}:</span></td>
                  <td width="80%">
                    <div className="history-event">
                      {fromValue ? <div className={toValue ? 'text-muted' : 'text-through'}>{fromValue}</div> : null}
                      {fromValue && toValue ? <i className="fa fa-xs fa-chevron-right text-muted" /> : null}
                      {toValue ? <div>{toValue}</div> : null}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    ) : null}
  </Panel>
);

export default HistoryEvent;
