import React, { Component } from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PulseLoader from 'halogenium/lib/PulseLoader';
import { getFeatures } from '@State/selectors';
import { setCustomerFormState } from '@State/cf-actions';
import CurrencyUtil from '@Utils/currency-util';
import { txt } from '@Utils/i18n-util';
import PosInvoiceUpdateStatus from '@Components/admin/pos-config/pos-invoice-update-status';
import { styleHiddenInput } from './style';
import msg from './booking-price.msg';

class BookingPrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editPrice: false,
      loading: false
    };
  }

  componentDidMount() {
    if (this.state.editPrice) {
      this.focusPriceField();
    }
    if (this.props.sales?.length > 0) {
      this.fetchSale(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    const hadNoSales = !prevProps.sales || prevProps.sales.length === 0;
    const hasSales = this.props.sales?.length > 0;

    if (hadNoSales && hasSales) {
      this.fetchSale(this.props);
    }
  }

  fetchSale = (props) => {
    const { id, sales } = props;
    console.log('Fetching sale for booking', { id, sales });

    this.setState({ loading: true });
    this.props.onFetchSale(id)
      .then(() => this.setState({ loading: false }));
  };

  focusPriceField = () => {
    if (this.price) {
      const price = ReactDOM.findDOMNode(this.price);
      price.focus();
    }
  };

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleClick = (ev) => {
    ev.preventDefault();
    this.focusHiddenField();
    this.setState({ editPrice: true }, () => this.focusPriceField());
  };

  handleOpenCustomer = (ev) => {
    ev.preventDefault();
    this.props.onOpenCustomer(this.props.customer);
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editPrice: false });
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={styleHiddenInput} />;
  }

  paymentProviderIcon = (provider) => {
    switch (provider) {
      case 'Swish':
      case 'SwishMerchant':
        return <img src="/swish.svg" alt="Swish" className="icon" key={provider} />;
      case 'Klarna':
      case 'KlarnaPayments':
        return <img src="/klarna.svg" alt="Klarna" className="icon" key={provider} />;
      case 'Stripe':
      case 'Card':
      case 'ExternalCardReader':
        return <i className="fa fa-credit-card" key={provider} />;
      case 'Cash':
        return <i className="fa fa-coins" key={provider} />;
      case 'Voucher':
        return <i className="fa fa-gift" key={provider} />;
      case 'PrepaidBankPayment':
        return <i className="fa fa-money-bill-transfer" key={provider} />;
      case 'Invoice':
        return <i className="far fa-file-invoice" key={provider} />;
      case 'FortnoxInvoice':
        return <img src="/fortnox-icon.png" alt="Fortnox" className="icon" key={provider} />;
      default:
        return null;
    }
  }

  paymentStatusIcon = (paymentStatus) => {
    switch (paymentStatus) {
      case 'Unpaid':
        return 'fa fa-badge-dollar text-muted';
      case 'Paid':
        return 'fa fa-badge-dollar text-success';
      case 'Refunded':
        return 'fa fa-arrow-circle-left text-muted';
      case 'RefundFailed':
        return 'fa fa-exclamation-cirle text-danger';
      default:
        return 'fa fa-question-circle text-muted';
    }
  }

  invoiceStatusIcon = (invoiceStatus) => {
    switch (invoiceStatus) {
      case 'Paid':
        return 'fa fa-badge-dollar text-success';
      case 'Annulled':
        return 'fa fa-arrow-circle-left text-muted';
      case 'DebtCollection':
        return 'fa fa-exclamation-cirle text-danger';
      default:
        return 'fa fa-badge-dollar text-muted';
    }
  }

  render() {
    const { editPrice, loading } = this.state;
    const {
      payments, sales, sale, price, status, services, customer
    } = this.props;
    const isCancelled = status === 'Cancelled';
    const hasServices = services && services.size > 0;
    const canEdit = !isCancelled && !hasServices;
    const hasSales = sales?.length > 0;

    if (hasSales && loading) {
      return (
        <div className="booking-form-block cancelled sale-loader">
          <PulseLoader color="#aaa" size="6px" margin="5px 5px 0 0" />
        </div>
      );
    }

    if (hasSales && sale) {
      const {
        paymentMethods, status, totalSaleAmount, servicesAmount,
        productsAmount, discountsAmount, invoiceStatus
      } = sale;
      const { paidTs } = sales[0];
      return (
        <div className={customer ? 'booking-form-block' : 'booking-form-block cancelled'} onClick={customer ? this.handleOpenCustomer : null}>
          <h4 className="sale-header">
            <div className="pull-right">
              <small>{moment(paidTs).format('LL')}</small>&nbsp;
              {paymentMethods.map(paymentMethod => this.paymentProviderIcon(paymentMethod))}
            </div>
            <i className={invoiceStatus
              ? this.invoiceStatusIcon(invoiceStatus.status)
              : this.paymentStatusIcon(status)}
            />
            {CurrencyUtil.accountCurrency(totalSaleAmount)}
          </h4>
          {invoiceStatus ? (
            <>
              <div className="sale-details">
                {invoiceStatus.status === 'Paid' ? (
                  <span>Faktura betald {moment(invoiceStatus.paidDate).format('LL')}</span>
                ) : (
                  <span className="text-muted">Ej betald</span>
                )}
                {invoiceStatus.provider === 'Fortnox' && (
                  <PosInvoiceUpdateStatus
                    posOrgId={invoiceStatus.posOrgId}
                    invoiceId={invoiceStatus.invoiceId}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="sale-details">
              <div>
                <strong>{txt(msg.lblServices)}</strong>
                <br />
                <span>{CurrencyUtil.accountCurrency(servicesAmount)}</span>
              </div>
              <div>
                <strong>{txt(msg.lblProducts)}</strong>
                <br />
                <span>{productsAmount ? CurrencyUtil.accountCurrency(productsAmount) : '-'}</span>
              </div>
              <div>
                <strong>{txt(msg.lblDiscount)}</strong>
                <br />
                <span>{discountsAmount ? CurrencyUtil.accountCurrency(discountsAmount) : '-'}</span>
              </div>
            </div>
          )}
        </div>
      );
    }

    if (payments?.length > 0) {
      const prePayments = payments.filter(p => p.paymentType === 'PrePaid');
      return (
        <div className="booking-form-block cancelled">
          <h4 className="pull-right mt0 mb0">
            {CurrencyUtil.accountCurrency(price)}
          </h4>
          Att betala
          {prePayments?.length > 0 && (
            <div className="sale-details">
              <table width="100%">
                <thead>
                  <tr>
                    <th>Förbetalt</th>
                    <th>Datum</th>
                    <th>Källa/referens</th>
                  </tr>
                </thead>
                <tbody>
                  {prePayments.map(({ id, transactionDate, amount, source, providerRef }) => (
                    <tr key={id}>
                      <td>{CurrencyUtil.accountCurrency(amount)}</td>
                      <td>{moment(transactionDate).format('L')}</td>
                      <td>{[source, providerRef].filter(s => s).join(', ') || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      );
    }

    if (canEdit && editPrice) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{txt(msg.lblPrice)}</label>
              <label className="form-control-label">{CurrencyUtil.currencySymbol()}</label>
              <Field name="price" component="input" type="number" className="form-control" ref={(ref) => { this.price = ref; }} />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (canEdit && (!price || price === 0)) {
      return (
        <button className="booking-form-block button" onClick={this.handleClick}>
          <i className="fa fa-dollar-sign" /> {txt(msg.btnAddPrice)}
        </button>
      );
    }

    return (
      <div className={!canEdit ? 'booking-form-block one-line cancelled' : 'booking-form-block one-line'} onClick={canEdit ? this.handleClick : null}>
        <h4 className="pull-right mt0 mb0">
          {CurrencyUtil.accountCurrency(price)}
        </h4>
        {txt(msg.lblPriceToPay)}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;
  const features = getFeatures(state);

  return {
    id: bkf.get('id'),
    initialValues: bkf.get('service'),
    services: bkf.get('services'),
    customer: bkf.get('customer'),
    payments: bkf.get('payments'),
    sales: bkf.get('sales'),
    sale: bkf.get('sale'),
    enablePos: features.EnablePOS,
    ...bkf.get('attributes'),
    ...getFormValues('bkf-price')(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenCustomer: (customer) => {
      dispatch(setCustomerFormState({
        formVisible: true,
        tab: 'sales',
        customer
      }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-price',
  destroyOnUnmount: false
})(BookingPrice));
