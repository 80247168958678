import React from 'react';
import { useSelector } from 'react-redux';
import { getOrgWideCustomerDb } from '@State/selectors';
import { getDescription } from '@Utils/booking-util';
import { getTimeByFormat } from '@Utils/time-util';
import {
  getSmsStatus, getChannel, getSmsSent, getSmsType
} from '@Utils/customer-util';
import { MONTH_DAY_YEAR_TIME } from '@Utils/time-constants';
import { PanelWrapper, UpperText } from '@Components/customers/customer-styles';
import { FormGroup } from '@Components/ui/styled/main';
import HistoryButton from '@Components/customers/history-button';
import BookingLabelsPanel from '@Components/customers/customer-detail/bookings/booking-labels-panel';
import { txt } from '@Utils/i18n-util';
import msg from './customer-booking.msg';

const CustomerBooking = ({
  resources, description, services, id, createdTime, channel, note,
  locationId, orgNo, orgName, vehicleRegNo, vehicleDescription,
  lastUpdateTime, reminders, cancelledTime, status, dropIn,
  askedForPerson, bookedAs, customerName
}) => {
  const orgWideCustomerDb = useSelector(getOrgWideCustomerDb);
  const locationNames = useSelector(state => state.locationNames);

  return (
    <PanelWrapper defaultCursor>
      <BookingLabelsPanel
        id={id}
        status={status}
        dropIn={dropIn}
        askedForPerson={askedForPerson}
      />

      {orgWideCustomerDb && <FormGroup labelText="Plats" text={locationNames.get(locationId)} />}
      {orgNo && <FormGroup labelText={txt(msg.lblOrgNo)} text={orgNo} />}
      {orgName && <FormGroup labelText={txt(msg.lblOrgName)} text={orgName} />}
      {vehicleRegNo && <FormGroup labelText={txt(msg.lblVehicleRegNo)} text={vehicleRegNo} />}
      {vehicleDescription && <FormGroup labelText={txt(msg.lblVehicleDesc)} text={vehicleDescription} />}

      <FormGroup
        labelText={txt(msg.lblResourceName)}
        text={resources.map(r => r.name).join(', ') || '-'}
      />
      {bookedAs && bookedAs !== customerName && (
        <FormGroup
          labelText={txt(msg.lblBookedAs)}
          text={bookedAs}
        />
      )}
      <FormGroup
        labelText={txt(msg.lblService)}
        text={getDescription(description, services) || '-'}
      />
      <FormGroup
        labelText={txt(msg.lblBookingNo)}
        text={id || '-'}
      />
      <FormGroup
        labelText={txt(msg.lblCreated)}
        text={createdTime ? getTimeByFormat(createdTime, MONTH_DAY_YEAR_TIME) : '-'}
      />
      <FormGroup
        labelText={txt(msg.lblChannel)}
        text={getChannel(channel) || '-'}
      />
      <FormGroup
        labelText={txt(msg.lblUpdated)}
        text={lastUpdateTime ? getTimeByFormat(lastUpdateTime, MONTH_DAY_YEAR_TIME) : '-'}
      />
      {reminders && reminders.filter(({ status }) => getSmsSent(status)).map(({ type, status, statusTs }) => (
        <FormGroup
          key={type}
          labelText={getSmsType(type)}
          text={<UpperText>{`${getSmsStatus(status)} ${getTimeByFormat(statusTs, MONTH_DAY_YEAR_TIME)}`}</UpperText>}
        />
      ))}
      <FormGroup
        labelText={txt(msg.lblCancelled)}
        text={cancelledTime ? getTimeByFormat(cancelledTime, MONTH_DAY_YEAR_TIME) : '-'}
      />
      <FormGroup
        labelText={txt(msg.lblNote)}
        text={note || '-'}
      />
      <HistoryButton white bookingId={id} />
    </PanelWrapper>
  );
};

export default CustomerBooking;
