import moment from 'moment';
import PT from 'prop-types';
import s from 'underscore.string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import ReactDOM from 'react-dom';
import { toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import { txt } from '@Utils/i18n-util';
import { styleHiddenInput } from './style';
import BookingDetails from './booking-details';
import msg from './booking-info.msg';

class BookingInfo extends Component {
  static propTypes = {
    id: PT.oneOfType([PT.number, PT.string]).isRequired,
    status: PT.string.isRequired,
    bookedAs: PT.string,
    createdTime: PT.object,
    cancelledTime: PT.object,
    lastUpdateTime: PT.object,
    channel: PT.string.isRequired,
    cancelledChannel: PT.string,
    reminders: PT.array,
    type: PT.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      editTime: false
    };
  }

  focusDurationField = () => {
    if (this.duration) {
      const duration = ReactDOM.findDOMNode(this.duration);
      duration.focus();
    }
  };

  focusHiddenField = () => {
    if (this.hidden) {
      const hidden = ReactDOM.findDOMNode(this.hidden);
      hidden.style.visibility = 'visible';
      hidden.focus();
      hidden.style.visibility = 'hidden';
    }
  };

  handleEdit = (ev) => {
    ev.preventDefault();
    this.focusHiddenField();
    this.setState({ editTime: true }, () => this.focusDurationField());
  };

  handleClose = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ editTime: false });
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={styleHiddenInput} />;
  }

  showDetails = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showDetails: true });

    if (!this.props.allReminders) {
      this.props.onFetchReminders(this.props.id);
    }
  };

  hideDetails = (ev) => {
    ev.target.blur();
    ev.preventDefault();
    this.setState({ showDetails: false });
  };

  showEvents = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onShowEvents();
  };

  render() {
    const { showDetails, editTime } = this.state;
    const {
      id, startTime, endTime, afterTime, status
    } = this.props;
    const isCancelled = status === 'Cancelled';

    const isNew = id === 'DRAGGER';
    const hasAfterTime = afterTime > 0;
    const displayEndTime = hasAfterTime ? moment(endTime).subtract(afterTime, 'm') : endTime;

    if (editTime) {
      return (
        <div className="booking-form-block top-part form">
          <div className="row tight">
            <div className="form-group col-xs-6">
              <label className="control-label">{txt(msg.duration)}</label>
              <label className="form-control-label">{txt(msg.unitMinutes)}</label>
              <Field name="serviceDuration" component="input" type="number" className="form-control" />
            </div>
            <div className="form-group col-xs-6">
              <label className="control-label">{txt(msg.aftertime)}</label>
              <label className="form-control-label">{txt(msg.unitMinutes)}</label>
              <Field name="afterTime" component="input" type="number" className="form-control" />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.closeBtnLabel)}</button>
          </div>
        </div>
      );
    }

    return (
      <div className="booking-form-block top-part nopointer">
        {startTime && endTime && (
          <div>
            <div className="block-buttons">
              {!isCancelled && <button className="btn-label" tabIndex={-1} onClick={this.handleEdit}>{txt(msg.editBtnLabel)}</button>}
            </div>
            <h4>
              {startTime.format('HH:mm')} - {displayEndTime.format('HH:mm')} {hasAfterTime && (
              <small>+ {afterTime} {txt(msg.unitMinutes)}</small>
              )}
            </h4>
            {!isNew && (
              <div className="pull-right">
                {showDetails
                  ? (
                    <button type="button" className="btn-link" tabIndex={-1} onClick={this.hideDetails}>
                      <i className="fa fa-chevron-circle-down" /> {txt(msg.hideDetailsBtnLabel)}
                    </button>
                  )
                  : (
                    <button type="button" className="btn-link" tabIndex={-1} onClick={this.showDetails}>
                      <i className="fa fa-chevron-circle-right" /> {txt(msg.showDetailsBtnLabel)}
                    </button>
                  )}
              </div>
            )}
            <small>
              {s.capitalize(startTime.format('dddd LL'))}
            </small>
          </div>
        )}
        {showDetails && (
          <>
            <BookingDetails {...this.props} />
            <div className="text-left">
              <button type="button" className="btn-label" tabIndex={-1} onClick={this.showEvents}>
                {txt(msg.showHistoryBtnLabel)}
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    id: bkf.get('id'),
    initialValues: bkf.get('service'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    payments: bkf.get('payments'),
    allReminders: bkf.get('reminders'),
    review: bkf.get('review'),
    ...bkf.get('attributes'),
    ...getFormValues('bkf-time')(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowEvents: () => dispatch(toggleBKFModal(bkfModals.events, true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-time',
  destroyOnUnmount: false
})(BookingInfo));
