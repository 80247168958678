import PT from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setBKFAttributes } from '@State/bkf/actions';
import {
  bookingFlagChanged, bookingStatusChanged,
  changeBookingFlag, changeBookingStatus
} from '@State/booking-actions';
import { txt } from '@Utils/i18n-util';
import msg from './booking-flags.msg';

class BookingFlags extends Component {
  static propTypes = {
    id: PT.oneOfType([
      PT.string,
      PT.number
    ]).isRequired,
    status: PT.string.isRequired,
    dropIn: PT.bool.isRequired,
    askedForPerson: PT.bool.isRequired,
    onStatusChange: PT.func.isRequired,
    onFlagChange: PT.func.isRequired
  };

  handleAFPClick = () => {
    const { id, askedForPerson, onFlagChange } = this.props;
    onFlagChange(id, { askedForPerson: !askedForPerson });
  };

  handleDropInClick = () => {
    const { id, dropIn, onFlagChange } = this.props;
    onFlagChange(id, { dropIn: !dropIn });
  };

  handleShowClick = () => {
    const { id, status, onStatusChange } = this.props;
    onStatusChange(id, status === 'Show' ? 'Booked' : 'Show');
  };

  handleNoShowClick = () => {
    const { id, status, onStatusChange } = this.props;
    onStatusChange(id, status === 'NoShow' ? 'Booked' : 'NoShow');
  };

  render() {
    const {
      id, askedForPerson, dropIn, status
    } = this.props;

    const isNew = id === 'DRAGGER';
    const isCancelled = status === 'Cancelled';
    const baseClasses = 'label label-default';
    const askedForClasses = baseClasses + (askedForPerson ? ' active label-asked-for-person' : '');
    const dropInClasses = baseClasses + (dropIn ? ' active label-drop-in' : '');
    const showClasses = baseClasses + (status === 'Show' ? ' active label-status-show' : '');
    const noShowClasses = baseClasses + (status === 'NoShow' ? ' active label-status-no-show' : '');

    return (
      <div className={isCancelled ? 'booking-form-flags disabled' : 'booking-form-flags'}>
        {!isNew && (
          <div className="pull-right">
            <span className={showClasses} onClick={this.handleShowClick}>{txt(msg.showLabel)}</span> <span className={noShowClasses} onClick={this.handleNoShowClick}>{txt(msg.noShowLabel)}</span>
          </div>
        )}
        <div>
          <span className={askedForClasses} onClick={this.handleAFPClick}>{txt(msg.askedForLabel)}</span> <span className={dropInClasses} onClick={this.handleDropInClick}>{txt(msg.dropInLabel)}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    id: bkf.get('id'),
    ...bkf.get('attributes')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onStatusChange: (id, status) => {
      dispatch(setBKFAttributes({ status }));

      const change = { bookingId: id, status };
      if (id !== 'DRAGGER') {
        dispatch(changeBookingStatus(change));
      } else {
        dispatch(bookingStatusChanged(change));
      }
    },
    onFlagChange: (id, flags) => {
      dispatch(setBKFAttributes({ ...flags }));

      const change = { bookingId: id, flags };
      if (id !== 'DRAGGER') {
        dispatch(changeBookingFlag(change));
      } else {
        dispatch(bookingFlagChanged(change));
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingFlags);
