import React, { Component } from 'react';
import { connect } from 'react-redux';
import { company, web } from '@Utils/preference-keys';
import { toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import Modal from '@Components/ui/modal';
import { getTimeString } from '@Utils/time-util';
import { formatPhoneNumber } from '@Utils/phone-util';
import { formatOrgNo } from '@Utils/luhn-util';
import { formatVehicleRegNo } from '@Utils/vehicle-util';
import { txt } from '@Utils/i18n-util';

import msg from './booking-print-modal.msg';

class BookingPrintModal extends Component {
  componentDidMount() {
    document.body.classList.add('print-hidden');
  }

  componentWillUnmount() {
    document.body.classList.remove('print-hidden');
  }

  onPrint = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    window.print();
  };

  render() {
    const {
      onClose, companyName, contactNumber, addressLine1, area, resources, resourceLabel,
      startTime, endTime, services, customer, company, vehicle, note, service
    } = this.props;
    const title = `Bokning hos ${companyName}`;
    const address = [addressLine1, area].filter(s => s).join(', ');

    return startTime ? (
      <Modal
        titleText={title}
        focusDialog
        underlayClickExits
        mounted
        onExit={onClose}
        dialogClass="modal-dialog print-dialog"
      >
        <div className="modal-content">
          <div className="modal-body print-visible">
            <div className="pull-right print-hidden">
              <button onClick={this.onPrint} className="btn-save mr1">{txt(msg.btnPrint)}</button>
              <button onClick={this.props.onClose} className="btn btn-default">{txt(msg.btnClose)}</button>
            </div>
            <h3 className="mt0 mb1">{title}</h3>
            <p className="mt0 mb4">{startTime.format('LLLL')} - {endTime.format('HH:mm')}</p>
            <hr />

            <table className="table-print">
              <tbody>
                <tr>
                  <th>{resourceLabel || txt(msg.lblResource)}</th>
                  <td>{resources.map(r => r.name).join(', ')}</td>
                </tr>
                <tr>
                  <th>{txt(msg.lblService)}</th>
                  <td>{services?.size > 0
                    ? services.valueSeq().map(s => (
                      <div key={s.id}>{s.name} ({getTimeString(s.duration)})</div>
                    )) : service?.name}
                  </td>
                </tr>
                {vehicle && (
                  <tr>
                    <th>{txt(msg.lblVehicle)}</th>
                    <td>
                      {vehicle.vehicleRegNo && <div>{formatVehicleRegNo(vehicle.vehicleRegNo)}</div>}
                      {vehicle.vehicleDescription && <div>{vehicle.vehicleDescription}</div>}
                    </td>
                  </tr>
                )}
                {company && (
                  <tr>
                    <th>{txt(msg.lblCompany)}</th>
                    <td>
                      {company.orgName && <div>{company.orgName}</div>}
                      {company.orgNo && <div>{formatOrgNo(company.orgNo)}</div>}
                    </td>
                  </tr>
                )}
                {customer && (
                  <tr>
                    <th>{txt(msg.lblCustomerDetails)}</th>
                    <td>
                      {customer.name && <div>{customer.name}</div>}
                      {customer.phoneNumber && <div>{formatPhoneNumber(customer.phoneNumber)}</div>}
                      {customer.email && <div>{customer.email}</div>}
                    </td>
                  </tr>
                )}
                {note && (
                  <tr>
                    <th>{txt(msg.lbNotes)}</th>
                    <td className="text-wrap">{note}</td>
                  </tr>
                )}
              </tbody>
            </table>

            {(address || contactNumber) && (
              <p>
                <hr />
                <strong>{companyName}</strong>
                <br />
                {address && (
                <span>
                  {address}
                  <br />
                </span>
                )}
                {contactNumber && (
                <span>
                  {txt(msg.lblContactPhone)}: {contactNumber}
                </span>
                )}
              </p>
            )}
          </div>
        </div>
      </Modal>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  const { bkf, locationConfig } = state;

  return {
    resourceLabel: locationConfig.get(web.resourceLabel),
    companyName: locationConfig.get(company.companyName),
    contactNumber: locationConfig.get(company.contactNumber),
    addressLine1: locationConfig.get(company.addressLine1),
    area: locationConfig.get(company.area),
    resources: bkf.get('resources'),
    startTime: bkf.get('startTime'),
    endTime: bkf.get('endTime'),
    customer: bkf.get('customer'),
    company: bkf.get('company'),
    services: bkf.get('services'),
    service: bkf.get('service'),
    vehicle: bkf.get('vehicle'),
    note: bkf.get('note'),
    ...bkf.get('attributes')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => dispatch(toggleBKFModal(bkfModals.print, false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPrintModal);
