import axios from 'axios';
import moment from 'moment';
import s from 'underscore.string';
import {
  axiosDefault, axiosErrorHandler, checkStatus, checkStatusAxios, fetchDelete,
  fetchErrorHandler, fetchGet, fetchPost, fetchPut, prefixSearchUrl, prefixUrl
} from '@Utils/ajax-util';
import { getFeatures } from '@State/selectors';
import { getSaleBookingIds } from '@State/pos-selectors';
import { resourceFromColIdx } from '@Components/calendar/grid/grid-state-helper';
import { calendar } from '@Utils/preference-keys';
import { loading, loadingDone, success } from './network-actions';

export const REQUEST_BOOKINGS = 'REQUEST_BOOKINGS';
export const MOVE_BOOKING = 'MOVE_BOOKING';
export const REVERT_BOOKING = 'REVERT_BOOKING';
export const CHANGE_BOOKING = 'CHANGE_BOOKING';
export const CONFIRM_BOOKING = 'CONFIRM_BOOKING';
export const ADD_BOOKING = 'ADD_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const REFUND_BOOKING = 'REFUND_BOOKING';
export const CHANGE_BOOKING_STATUS = 'CHANGE_BOOKING_STATUS';
export const CHANGE_BOOKING_FLAG = 'CHANGE_BOOKING_FLAG';
export const CHANGE_BOOKING_TYPE = 'CHANGE_BOOKING_TYPE';
export const SET_UNDOABLE_BOOKING = 'SET_UNDOABLE_BOOKING';
export const SEARCH_BOOKINGS = 'SEARCH_BOOKINGS';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_SEARCH_SCROLL_POS = 'SET_SEARCH_SCROLL_POS';
export const SHOW_SEARCH = 'SHOW_SEARCH';
export const CLEAR_BOOKINGS = 'CLEAR_BOOKINGS';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const IS_SEARCH_BOOKINGS = 'IS_SEARCH_BOOKINGS';
export const PRE_PAYMENT_ADDED = 'PRE_PAYMENT_ADDED';
export const POS_PRE_PAYMENTS_FETCHED = 'POS_PRE_PAYMENTS_FETCHED';

export function fetchBooking(id) {
  const url = prefixUrl(`/bookings/${id}`);

  return (dispatch) => {
    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function deleteBooking(id) {
  const url = prefixUrl(`/bookings/${id}`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchDelete())
      .then(res => dispatch(checkStatus(res)))
      .then(req => dispatch(loadingDone()))
      .then(req => dispatch(bookingDeleted(id, 'local')))
      .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(id)))));
  };
}

export function bookingDeleted(id, source) {
  return {
    type: DELETE_BOOKING,
    id,
    source
  };
}

export function changeBookingFlag(change) {
  const { bookingId, flags } = change;

  let flag = Object.keys(flags)[0];
  const flagOn = flags[flag];

  flag = s.capitalize(flag);

  const url = prefixUrl(`/bookings/${bookingId}/flags/${flag}`);
  const method = flagOn ? fetchPut() : fetchDelete();

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, method)
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(bookingFlagChanged(change, 'local')))
      .then(res => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(bookingId)))));
  };
}

export function bookingFlagChanged(change, source = 'local') {
  return {
    type: CHANGE_BOOKING_FLAG,
    change,
    source
  };
}

export function cancelBooking(data) {
  const bkId = data.bookingId;
  const { options } = data;

  const changes = {
    cancelledTime: moment(),
    cancelledChannel: 'Cal'
  };

  const url = prefixUrl(`/bookings/${bkId}/cancelled`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPut(options))
      .then(res => dispatch(checkStatus(res)))
      .then(() => {
        if (options.deleteBooking) {
          dispatch(bookingDeleted(bkId));
        } else {
          dispatch(bookingCancelled(bkId, changes));
        }
      })
      .then(res => dispatch(success('Bokningen har bokats av')))
      .then(res => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(bkId)))));
  };
}

export function bookingCancelled(id, changes, source = 'local') {
  return {
    type: CANCEL_BOOKING,
    id,
    changes,
    source
  };
}

export function refundBooking(id) {
  const url = prefixUrl(`/bookings/${id}/refund`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPost())
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(bookingRefunded(id, res)))
      .then(res => dispatch(success('Bokningen har återbetalats')))
      .then(res => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(id)))));
  };
}

export function bookingRefunded(id, refund, source = 'local') {
  return {
    type: REFUND_BOOKING,
    id,
    refund,
    source
  };
}

export function sendBookingConfirmation(data) {
  const bkId = data.bookingId;
  const { options } = data;

  const url = prefixUrl(`/bookings/${bkId}/send-confirmation`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPost(options))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(success('Bokningsbekräftelse har skickats')))
      .then(req => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function sendBookingReceipt({ paymentRef, toName, toEmail }) {
  const url = prefixUrl(`/receipts/${paymentRef}/email-copy`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPost({ toName, toEmail }))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(success(`Kvitto har skickats till ${toEmail}`)))
      .then(req => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function changeBookingStatus(change) {
  const bkId = change.bookingId;

  const url = prefixUrl(`/bookings/${bkId}/status/${change.status}`);

  return (dispatch) => {
    dispatch(loading());

    return fetch(url, fetchPut())
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(bookingStatusChanged(change, 'local')))
      .then(res => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(bkId)))));
  };
}

export function bookingStatusChanged(change, source = 'local') {
  return {
    type: CHANGE_BOOKING_STATUS,
    change,
    source
  };
}

export function bookingTypeChanged(change, source = 'local') {
  return {
    type: CHANGE_BOOKING_TYPE,
    change,
    source
  };
}

export function bookingChanged(id, booking, source = 'local') {
  return {
    type: CHANGE_BOOKING,
    id,
    booking,
    source
  };
}

export function bookingConfirmed(id, booking, source = 'local') {
  return {
    type: CONFIRM_BOOKING,
    id,
    booking,
    source
  };
}

export function removeTempBooking(id) {
  return bookingDeleted(id, 'local', true);
}

export function addTempBooking(booking, source = 'local') {
  return {
    type: ADD_BOOKING,
    booking,
    source,
    hideBookingForm: false
  };
}

export function bookingAdded(booking, source = 'local') {
  return {
    type: ADD_BOOKING,
    booking,
    source
  };
}

export function setUndoableBooking(booking) {
  return {
    type: SET_UNDOABLE_BOOKING,
    booking
  };
}

export function undoMove(routeParams) {
  return (dispatch, getState) => {
    const undoState = getState().gridViewState.get('undoableBooking');
    if (undoState != null) {
      dispatch(setUndoableBooking(null));
      dispatch(moveBooking(undoState.toJS(), routeParams, true));
    }
  };
}

export function getUndoState(booking, move) {
  return {
    ...booking,
    sourceResourceId: move.targetResourceId,
    targetResourceId: move.sourceResourceId
  };
}

export function moveBooking(move, routeParams, isUndo = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { bookingsById, locationConfig } = state;
    const isUndoable = !locationConfig.get(calendar.confirmMoveEnabled);

    const booking = bookingsById.get(move.id);
    const sourceResourceId = resourceFromColIdx(state, routeParams, move.sourceColIdx)?.id || booking.resourceId;
    const targetResourceId = resourceFromColIdx(state, routeParams, move.colIdx)?.id || booking.resourceId;

    if (sourceResourceId !== targetResourceId && booking.resources.some(r => r.id === targetResourceId)) {
      console.error('Cannot move to existing resource for booking');
      dispatch(revertBooking(move.id, booking));
      return;
    }

    const moveEvent = isUndo ? move : {
      ...move,
      sourceResourceId,
      targetResourceId
    };
    const undoState = isUndo ? null : getUndoState(booking, moveEvent);
    const confirmations = isUndoable ? {} : {
      sendSmsConfirmation: isUndo ? false : moveEvent.sendSmsConfirmation,
      sendEmailConfirmation: isUndo ? false : moveEvent.sendEmailConfirmation
    };

    const body = {
      ...confirmations,
      startTime: moveEvent.startTime.toISOString(),
      endTime: moveEvent.endTime.toISOString(),
      sourceResourceId: moveEvent.sourceResourceId,
      targetResourceId: moveEvent.targetResourceId
    };

    const url = prefixUrl(`/bookings/${moveEvent.id}/move`);
    return fetch(url, fetchPost(body))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(res => dispatch(bookingMoved({ ...moveEvent, ...res }, isUndo)))
      .then(req => dispatch(setUndoableBooking(isUndoable ? undoState : null)))
      .then(req => dispatch(loadingDone()))
      .catch(error => dispatch(fetchErrorHandler(error, () => dispatch(revertBooking(moveEvent.id)))));
  };
}

export function revertBooking(bookingId, booking) {
  return {
    type: REVERT_BOOKING,
    bookingId,
    booking
  };
}

export function bookingMoved(moveEvent, isUndo) {
  return {
    type: MOVE_BOOKING,
    booking: moveEvent,
    isUndo
  };
}

export function showSearch() {
  return {
    type: SHOW_SEARCH
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH
  };
}

export function saveSearchScrollPos(scrollPos) {
  return {
    type: SET_SEARCH_SCROLL_POS,
    scrollPos
  };
}

export function isSearching(isSearching = false) {
  return {
    type: IS_SEARCH_BOOKINGS,
    isSearching
  };
}

export function searchBookings(query) {
  return (dispatch, getState) => {
    dispatch(isSearching(true));
    if (query && query.length > 0) {
      dispatch(showSearch());
    }

    if (!query || query.length <= 2) {
      dispatch({ type: SEARCH_BOOKINGS, query, bookings: [] });
      dispatch(isSearching());
      return Promise.resolve();
    }

    const url = `/search/bookings?query=${encodeURIComponent(query)}`;
    const prefixedUrl = prefixSearchUrl(url, getState());
    const config = axiosDefault();

    dispatch(isSearching(true));
    return axios.get(prefixedUrl, config)
      .then(({ data }) => {
        dispatch(isSearching());
        dispatch({ type: SEARCH_BOOKINGS, query, bookings: data.result });
      })
      .catch(error => {
        dispatch(isSearching());
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function clearBookings() {
  return {
    type: CLEAR_BOOKINGS
  };
}

export function resetBookings() {
  return (dispatch, getState) => {
    const { bookingsById } = getState();
    const bks = bookingsById.valueSeq().toJS();
    dispatch(clearBookings());
    setTimeout(() => dispatch(setBookings(bks)), 1);
  };
}
export function setBookings(bookings) {
  return {
    type: SET_BOOKINGS,
    bookings
  };
}

export function addExternalPayment(payment) {
  return (dispatch) => {
    const { posOrgId, bookingId, ...data } = payment;
    const url = prefixUrl(`/pos/sales/org/${posOrgId}/booking/${bookingId}/external-payment/`);
    const config = axiosDefault();

    return axios.post(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then((res) => {
        const sale = res.saleId ? { ...res } : null;
        const newPayment = {
          amount: Number(data.paymentAmount),
          transactionDate: data.paymentDate,
          paymentType: 'PrePaid',
          source: data.source,
          providerRef: data.providerRef,
          paymentRef: res.paymentRef
        };
        dispatch({ type: PRE_PAYMENT_ADDED, bookingId, payment: newPayment, sale });
        return newPayment;
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

function fetchBookingPrePayments(bookingId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/sales/booking/${bookingId}/external-payment/`);
    const config = axiosDefault();

    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ payments }) => {
        dispatch({ type: POS_PRE_PAYMENTS_FETCHED, bookingId, prePayments: payments });
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function fetchSalePrePayments() {
  return (dispatch, getState) => {
    const state = getState();
    const bookingIds = getSaleBookingIds(state);
    const { EnablePrepaidBookings } = getFeatures(state);

    if (!EnablePrepaidBookings) {
      return;
    }

    return Promise.all(
      bookingIds.map(bookingId => {
        return dispatch(fetchBookingPrePayments(bookingId));
      })
    );
  };
}

export function completePrePayment({ paymentRef, email }) {
  return (dispatch) => {
    const receiptMethods = [];
    if (email) {
      receiptMethods.push(dispatch(sendBookingReceipt({ paymentRef, toEmail: email })));
    }
    return Promise.all(receiptMethods);
  };
}
