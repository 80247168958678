import axios from 'axios';
import queryString from 'query-string';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import { setBKFBooking } from '@State/bkf/actions';
import { isEmbeddedInApp } from '@Utils/embedded-util';
import {
  prefixUrl,
  axiosDefault,
  checkStatusAxios,
  isNetworkOrSafeRequestError
} from '@Utils/ajax-util';
import {
  saveHighContrastEnabled,
  saveGridSize,
  saveExternalKeyboardEnabled,
  saveShowChipTooltipEnabled
} from '@Utils/local-settings';
import { viewDateToStartEnd } from '@Utils/time-util';
import { postWebkitMessage } from '@Utils/wk-embed-bridges';
import { getElementDimensions, getViewDimensions } from '@Utils/dimensions';
import { getResourcesForNewBooking, isTimeInSchedule } from '@Components/calendar/grid/grid-state-helper';
import { getIsMultiResourceView, getResourcesInView } from '@State/calendar-selectors';
import { addTempBooking } from './booking-actions';
import { fetchAvailableSlots } from './find-time/actions';

export const SELECT_DATE = 'SELECT_DATE';
export const RECEIVE_VIEWDATA = 'RECEIVE_VIEWDATA';
export const LOADING_VIEWDATA = 'LOADING_VIEWDATA';

export const TOGGLE_GRIDMARKER = 'TOGGLE_GRIDMARKER';
export const UPDATE_GRIDMARKER = 'UPDATE_GRIDMARKER';
export const TOGGLE_DATEPICKER = 'TOGGLE_DATEPICKER';
export const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS';
export const SET_HIGH_CONTRAST = 'SET_HIGH_CONTRAST';
export const SET_SHOW_CHIP_TOOLTIP = 'SET_SHOW_CHIP_TOOLTIP';
export const SET_EXTERNAL_KEYBOARD = 'SET_EXTERNAL_KEYBOARD';
export const SET_CALENDAR_ROWS_PER_HOUR = 'SET_CALENDAR_ROWS_PER_HOUR';
export const SET_GRID_SIZE = 'SET_GRID_SIZE';

export const HIGHLIGHT_RESOURCE_BOOKINGS = 'HIGHLIGHT_RESOURCE_BOOKINGS';
export const TOGGLE_RESOURCE_LIST_COLLAPSED = 'TOGGLE_RESOURCE_LIST_COLLAPSED';
export const TOGGLE_SCHEDULE_EDIT_MODE = 'TOGGLE_SCHEDULE_EDIT_MODE';
export const TOGGLE_MULTI_RESOURCE_MODE = 'TOGGLE_MULTI_RESOURCE_MODE';
export const TOGGLE_GRID_SCROLLABILITY = 'TOGGLE_GRID_SCROLLABILITY';
export const STORE_VIEW_STATE = 'STORE_VIEW_STATE';

export const STAFF_JOURNAL_STATUS_FETCHED = 'STAFF_JOURNAL_STATUS_FETCHED';

export function storeViewState(viewState) {
  return {
    type: STORE_VIEW_STATE,
    viewState
  };
}

export function toggleScheduleEditMode(state) {
  return {
    type: TOGGLE_SCHEDULE_EDIT_MODE,
    state
  };
}

export function toggleMultiResourceMode(state) {
  return {
    type: TOGGLE_MULTI_RESOURCE_MODE,
    state
  };
}

export function highlightResourceBookings(resourceIds) {
  return {
    type: HIGHLIGHT_RESOURCE_BOOKINGS,
    resourceIds
  };
}

export function resetHighlightedBookings() {
  return (dispatch) => {
    window.chipHoverBookingId = null;
    dispatch(highlightResourceBookings(null));
  };
}

export function updateHighlightedBookings() {
  return (dispatch, getState) => {
    const { bookingsById, gridViewState } = getState();
    const highlightResourceIds = gridViewState.get('highlightResourceIds');

    const { chipHoverBookingId } = window;
    const booking = chipHoverBookingId ? bookingsById.get(chipHoverBookingId) : null;
    const newResourceIds = booking?.resources.map(r => r.id);

    if (highlightResourceIds !== newResourceIds) {
      dispatch(highlightResourceBookings(newResourceIds));
    }
  };
}

export function updateViewDimensions() {
  return (dispatch, getState) => {
    const embedded = isEmbeddedInApp(getState());
    const dimensions = getElementDimensions(embedded);
    const viewDimensions = getViewDimensions();

    dispatch({
      type: UPDATE_DIMENSIONS,
      ...dimensions,
      ...viewDimensions
    });
  };
}

export function toggleDatePicker(visible) {
  return {
    type: TOGGLE_DATEPICKER,
    visible
  };
}

export function toggleGridScrollability(scrollable) {
  return {
    type: TOGGLE_GRID_SCROLLABILITY,
    scrollable
  };
}

export function toggleResourceListCollapsed(collapsedIds) {
  return {
    type: TOGGLE_RESOURCE_LIST_COLLAPSED,
    collapsedIds
  };
}

export function dragSelected(event, routeParams) {
  return (dispatch, getState) => {
    const state = getState();
    const { gridViewState, schedulesByResource } = state;
    const embeddedInApp = isEmbeddedInApp(state);
    const scheduleEditMode = gridViewState.get('scheduleEditMode');
    const resources = getResourcesForNewBooking(state, routeParams, event.colIdx);
    const primaryResource = resources.find(r => r.primary);

    if (!primaryResource) {
      console.warn('No primary resource available. Cannot create booking.');
      return;
    }

    const gridMarkerState = {
      visible: false,
      duration: event.duration
    };

    const tempBooking = {
      id: 'DRAGGER',
      type: scheduleEditMode ? 'ScheduleException' : 'SimpleBooking',
      dropIn: false,
      askedForPerson: false,
      startTime: event.startTime,
      endTime: event.endTime,
      resourceId: primaryResource.id,
      resources,
      status: scheduleEditMode ? 'ScheduleDrag' : 'BookedDrag',
      reminders: [],
      channel: 'Cliento',
      pending: false,
      reservation: false,
      attributes: primaryResource.attributes
    };

    if (scheduleEditMode) {
      const isInSchedule = isTimeInSchedule(schedulesByResource, event.startTime, primaryResource.id);
      tempBooking.exceptionType = isInSchedule ? 'close' : 'open';
    }

    dispatch(addTempBooking(tempBooking));
    dispatch(toggleGridMarker(gridMarkerState));
    dispatch(toggleGridScrollability(false));

    if (embeddedInApp) {
      if (scheduleEditMode) {
        postWebkitMessage('editScheduleForm', tempBooking);
      } else {
        postWebkitMessage('addBookingForm', tempBooking);
      }
    } else {
      dispatch(setBKFBooking(tempBooking, event.colIdx, primaryResource.id));
    }
  };
}

export function updateGridMarker(update) {
  return {
    type: UPDATE_GRIDMARKER,
    update
  };
}

export function toggleGridMarker(state) {
  return {
    type: TOGGLE_GRIDMARKER,
    state
  };
}

export function setHighContrast(enabled) {
  saveHighContrastEnabled(enabled);
  return {
    type: SET_HIGH_CONTRAST,
    enabled
  };
}

export function setShowChipTooltip(enabled) {
  saveShowChipTooltipEnabled(enabled);
  return {
    type: SET_SHOW_CHIP_TOOLTIP,
    enabled
  };
}

export function setCalendarGridRowsPerHour(rowsPerHour) {
  return {
    type: SET_CALENDAR_ROWS_PER_HOUR,
    rowsPerHour
  };
}

export function setGridSize(size) {
  saveGridSize(size);
  return {
    type: SET_GRID_SIZE,
    size
  };
}

export function setExternalKeyboard(enabled) {
  saveExternalKeyboardEnabled(enabled);
  return {
    type: SET_EXTERNAL_KEYBOARD,
    enabled
  };
}

function loadingViewData() {
  return {
    type: LOADING_VIEWDATA,
    loadingFrom: Date.now()
  };
}

function receiveViewData(viewData, resources) {
  return {
    type: RECEIVE_VIEWDATA,
    viewData,
    resources,
    receivedAt: Date.now()
  };
}

const axiosWithRetry = axios.create();
axiosRetry(axiosWithRetry, {
  retryDelay: exponentialDelay,
  retries: 5,
  retryCondition: isNetworkOrSafeRequestError
});

function checkScheduleEditMode(routeParams) {
  return (dispatch, getState) => {
    const state = getState();
    const scheduleEditMode = state.gridViewState.get('scheduleEditMode');
    const resourcesInView = getResourcesInView(state, { routeParams });
    const isMultiResourceView = getIsMultiResourceView(state, { routeParams });
    const disabled = isMultiResourceView || resourcesInView.length === 0;

    if (scheduleEditMode && disabled) {
      dispatch(toggleScheduleEditMode(false));
    }
  };
}

function fetchCalendarViewData(resourceIds, startDate, endDate) {
  return (dispatch) => {
    const query = queryString.stringify({
      rid: resourceIds.join(','),
      sd: startDate,
      ed: endDate
    });
    const url = prefixUrl(`/calendar/view-data?${query}`);
    return axiosWithRetry
      .get(url, axiosDefault())
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data);
  };
}

export function fetchViewData(routeParams) {
  return (dispatch, getState) => {
    const state = getState();
    const { viewMode, viewDate } = routeParams;
    const resources = getResourcesInView(state, { routeParams });
    const findTime = state.findTime.get('showInCalendar');
    dispatch(checkScheduleEditMode(routeParams));

    if (!resources || resources.length === 0) {
      return Promise.resolve();
    }

    const dates = viewDateToStartEnd(viewMode, viewDate);
    const startDate = dates.start.format('YYYY-MM-DD');
    const endDate = dates.end.subtract(1, 'day').format('YYYY-MM-DD');
    const resourceIds = resources.map(r => r.id);

    dispatch(loadingViewData());
    return dispatch(fetchCalendarViewData(resourceIds, startDate, endDate))
      .then(viewData => {
        dispatch(receiveViewData(viewData, resources));

        if (findTime) {
          dispatch(fetchAvailableSlots(routeParams, true));
        }
      });
  };
}

export function staffJournalStatus() {
  return (dispatch) => {
    const config = axiosDefault();
    const url = prefixUrl('/staffjournal/status');
    return axios.get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ checkedIn }) => dispatch({ type: STAFF_JOURNAL_STATUS_FETCHED, checkedIn }));
  };
}
