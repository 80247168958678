import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getSaleCustomer } from '@State/pos-selectors';
import { setCustomerFormState } from '@State/cf-actions';
import SearchCustomerModal from '@Components/pos/sale/search-customer-modal';
import { addCustomerToSale } from '@State/pos-actions';
import CustomerModal from '@Components/customers/customer-modal';
import AddCustomerModal from '@Components/customers/add-customer-modal';

const Icon = styled.i`
  margin: -1px 6px 0 -3px;
  font-size: 12px;
`;

const Remove = styled.i`
  margin-left: 5px;
  margin-right: -5px;
  color: #999;
  padding: 5px 0 5px 5px;
`;

const SaleToolbarCustomerButton = ({
  routeParams, isSaleDisabled, showModal
}) => {
  const dispatch = useDispatch();
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showSearchCustomer, setShowSearchCustomer] = useState(false);

  const customer = useSelector(state => getSaleCustomer(state));

  const onRemoveCustomer = (ev) => {
    ev.stopPropagation();
    showModal('removeCustomer');
  };
  const onSearchCustomer = () => setShowSearchCustomer(true);
  const onHideSearchCustomer = () => setShowSearchCustomer(false);
  const onHideAddCustomer = () => setShowAddCustomer(false);
  const onCustomerSelected = ({ customer }) => {
    if (customer.customerId) {
      dispatch(addCustomerToSale(customer));
    } else {
      setShowAddCustomer(true);
    }
    onHideSearchCustomer();
  };
  const onCustomerCreated = (customer) => {
    dispatch(addCustomerToSale(customer));
    onHideAddCustomer();
  };
  const onOpenCustomer = () => dispatch(setCustomerFormState({
    formVisible: true,
    customer
  }));

  return (
    <>
      {customer ? (
        <button className="btn btn-default" onClick={onOpenCustomer} disabled={false}>
          <Icon className="fad fa-user" />
          <div>{customer.name}</div>
          <Remove className="fal fa-times-circle" onClick={!isSaleDisabled ? onRemoveCustomer : null} />
        </button>
      ) : (
        <button className="btn btn-default" onClick={onSearchCustomer} disabled={false}>
          <Icon className="fad fa-user" /> Lägg till kund
        </button>
      )}
      {showSearchCustomer && (
        <SearchCustomerModal
          onSelected={onCustomerSelected}
          onCancel={onHideSearchCustomer}
          maxHeight={document.body.scrollHeight}
          height={400}
          scope={['Customer']}
        />
      )}
      {showAddCustomer && (
        <AddCustomerModal
          routeParams={routeParams}
          onCreated={onCustomerCreated}
          onClose={onHideAddCustomer}
        />
      )}
      <CustomerModal routeParams={routeParams} />
    </>
  );
};

export default SaleToolbarCustomerButton;
