import React from 'react';
import { TitleDate, ContentBlock } from '@Components/customers/customer-styles';
import CustomerGiftCard from '@Components/customers/customer-detail/gift-cards/customer-gift-card';
import { sortEventByTimeAndReverse } from '@Utils/customer-util';

const CustomerGiftCardList = ({
  items = [], onSelectEvent, selectedEventId, isSelectable = false
}) => {
  const renderEventsBlock = (events, title) => (
    <>
      <TitleDate>{title}</TitleDate>
      <ContentBlock>
        {sortEventByTimeAndReverse(events).map((event) => (
          <div key={`${title}_${event?.id}`}>
            <CustomerGiftCard
              isSelectable={isSelectable}
              selectedEventId={selectedEventId}
              onSelectEvent={onSelectEvent}
              {...event}
            />
          </div>
        ))}
      </ContentBlock>
    </>
  );

  const valid = items.filter(g => g.valid);
  const invalid = items.filter(g => !g.valid && g.status !== 'Void');

  return (
    <div>
      {renderEventsBlock(valid, 'Giltiga')}
      {renderEventsBlock(invalid, 'Förbrukade/utgångna')}
    </div>
  );
};

export default CustomerGiftCardList;
