import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholderAddItem: {
    id: 'calendar.bkf.search-resource.placeholderAddItem',
    defaultMessage: 'Search resource'
  },
  btnDone: {
    id: 'calendar.bkf.search-resource.btnDone',
    defaultMessage: 'Done'
  },
  lblTitle: {
    id: 'calendar.bkf.search-resource.lblTitle',
    defaultMessage: 'Select resources'
  }
});
