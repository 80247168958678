import { defineMessages } from 'react-intl';

export default defineMessages({
  lblDescription: {
    id: 'calendar.bkf.service.lblDescription',
    defaultMessage: 'Description'
  },
  btnChange: {
    id: 'calendar.bkf.service.btnChange',
    defaultMessage: 'Add / remove'
  },
  btnChangePrice: {
    id: 'calendar.bkf.service.btnChangePrice',
    defaultMessage: 'Change price'
  },
  lblDuration: {
    id: 'calendar.bkf.service.lblDuration',
    defaultMessage: '{duration} min'
  },
  lblPriceFrom: {
    id: 'calendar.bkf.service.lblPriceFrom',
    defaultMessage: 'Price from '
  },
  btnClose: {
    id: 'calendar.bkf.service.btnClose',
    defaultMessage: 'Close'
  },
  btnAddService: {
    id: 'calendar.bkf.service.btnAddService',
    defaultMessage: 'Add services'
  }
});
